import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import AdCard2 from "../../components/AdComponent/adCard2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Youtube from "../../components/youtube/youtube";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useRef, useState } from "react";
import { QrCodeReader } from "../components/QrCodeReader";
import { useAuth } from "../../components/firebase/authContext";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../components/firebase/firebase";
import {
  collection,
  doc,
  getDocs,
  setDoc,
  runTransaction,
  query,
  limit,
  where,
  orderBy,
} from "firebase/firestore";
import getMainMaxWidth from "../../function/GetMainMaxWidth";

function RateRanking() {
  const [userList, setUserList] = useState([]);
  const maxWidth = getMainMaxWidth();

  useEffect(() => {
    async function fetchUserList() {
      try {
        const q = query(
          collection(db, "users"),
          where("public", "==", true),
          orderBy("rate", "desc"),
          limit(20)
        );
        const querySnapshot = await getDocs(q);
        const userListData: any = querySnapshot.docs.map((doc) => doc.data());

        setUserList(userListData);
      } catch (error) {
        console.error("Error getting user list:", error);
      }
    }

    fetchUserList();
  }, []); // 空の配列

  const navigate = useNavigate();
  // テーブルの見出のスタイル
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };
  const displayNameStyle = {
    border: "none",
    fontSize: 14,
    fontWeight: "bold",
    color: "gray",
    textOverflow: "ellipsis",
  };
  // テーブルの要素の
  const cellRightStyle = {
    border: "none",
    fontSize: 12,
    color: "black",
    textOverflow: "ellipsis",
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8, mb: 2 }}
      >
        {userList.map((user) => userCard(user))}
      </Grid>
    </div>
  );
  function userCard(userData: any) {
    return (
      <Card
        sx={{
          minWidth: 275,
          maxWidth: maxWidth,
          width: "95%",
          mt: 1,
          p: 1,
        }}
        onClick={() => {
          // カードがクリックされたときの処理
          console.log("Card clicked!");
          // 画面遷移を実行する例（遷移先のURLと一緒にデータを渡す）
          navigate("/userprofile", {
            state: {
              user: {
                displayName: userData.userDisplayName,
                photoURL: userData.userPhotoURL,
              },
              userData: userData,
            },
          });
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            alt="myPhoto"
            src={userData.userPhotoURL!}
            sx={{ width: 60, height: 60 }}
          />
          <Stack direction="column" spacing={-1}>
            <Stack direction="row" alignItems="end" spacing={1}>
              <Typography
                sx={{
                  ...displayNameStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData.userDisplayName}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData?.age ?? ""}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {userData?.sex ?? ""}
              </Typography>
              <Typography
                sx={{
                  ...cellLeftStyle,
                  mt: 1,
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {userData?.base ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ ...cellLeftStyle, mt: 1 }}>
                レート:　
              </Typography>
              <Typography sx={{ ...cellRightStyle, mt: 1 }}>
                {userData?.rate ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ ...cellLeftStyle, mt: 1 }}>
                フォア:　
              </Typography>
              <Typography sx={{ ...cellRightStyle, mt: 1 }}>
                {userData?.fRubber ?? ""}
              </Typography>
            </Stack>
            <Stack direction="row">
              <Typography sx={{ ...cellLeftStyle, mt: 1 }}>
                バック:　
              </Typography>
              <Typography sx={{ ...cellRightStyle, mt: 1 }}>
                {userData?.bRubber ?? ""}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }
}

export default RateRanking;
