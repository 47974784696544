import {
  Stack,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db } from "../../components/firebase/firebase";
import { collection, doc, updateDoc, setDoc } from "firebase/firestore";
import { useAuth } from "../../components/firebase/authContext";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import getMainMaxWidth from "../../function/GetMainMaxWidth";

type Inputs = {
  age: string;
  sex: string;
  rate: string;
  experience: any;
  playStyle: string;
  baseCity: string;
  base: string;
  comment: any;
  snsLink: string;
  videoLink: any;
  public: boolean;
  // bRubber: string
};

function EditProfile() {
  const maxWidth = getMainMaxWidth();
  const navigate = useNavigate();
  const { currentUser, userData, updateUserData } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    // デフォルト値、　nameで設定する
    defaultValues: {
      age: userData.age,
      sex: userData.sex,
      rate: userData.rate,
      experience: userData.experience,
      playStyle: userData.playStyle,
      base: userData.base,
      baseCity: userData.baseCity,
      comment: userData.comment,
      snsLink: userData.snsLink,
      videoLink: userData.videoLink,
      public: userData.public,
    },
  });
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };

  const validationRules = {
    playStyle: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 15, message: "15文字以下で入力してください。" },
    },
    base: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 15, message: "15文字以下で入力してください。" },
    },
    comment: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 200, message: "200文字以下で入力してください。" },
    },
  };

  const prefectures = [
    "宇都宮市",
    "足利市",
    "栃木市",
    "佐野市",
    "鹿沼市",
    "日光市",
    "小山市",
    "真岡市",
    "大田原市",
    "矢板市",
    "那須塩原市",
    "さくら市",
    "那須烏山市",
    "下野市",
    "上三川町",
    "益子町",
    "茂木町",
    "市貝町",
    "芳賀町",
    "壬生町",
    "野木町",
    "塩谷町",
    "高根沢町",
    "那須町",
    "那珂川町",
    "栃木県内",
    "栃木県北",
    "栃木県央",
    "栃木県南",
    "関東圏",
    "関東圏外",
    "---",
  ];

  // const prefectures = [
  //   "北海道",
  //   "青森県",
  //   "岩手県",
  //   "宮城県",
  //   "秋田県",
  //   "山形県",
  //   "福島県",
  //   "茨城県",
  //   "栃木県",
  //   "群馬県",
  //   "埼玉県",
  //   "千葉県",
  //   "東京都",
  //   "神奈川県",
  //   "新潟県",
  //   "富山県",
  //   "石川県",
  //   "福井県",
  //   "山梨県",
  //   "長野県",
  //   "岐阜県",
  //   "静岡県",
  //   "愛知県",
  //   "三重県",
  //   "滋賀県",
  //   "京都府",
  //   "大阪府",
  //   "兵庫県",
  //   "奈良県",
  //   "和歌山県",
  //   "鳥取県",
  //   "島根県",
  //   "岡山県",
  //   "広島県",
  //   "山口県",
  //   "徳島県",
  //   "香川県",
  //   "愛媛県",
  //   "高知県",
  //   "福岡県",
  //   "佐賀県",
  //   "長崎県",
  //   "熊本県",
  //   "大分県",
  //   "宮崎県",
  //   "鹿児島県",
  //   "沖縄県",
  // ];

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log(data);

    const submitData = [];

    const docRef = collection(db, "users");
    const updatedData: Record<string, any> = {};

    if (data.rate != userData.rate) {
      updatedData.rate = data.rate;
    }
    if (data.experience != userData.experience) {
      updatedData.experience = data.experience;
    }
    if (data.baseCity != userData.baseCity) {
      updatedData.baseCity = data.baseCity;
    }
    if (data.base != userData.base) {
      updatedData.base = data.base;
    }
    if (data.snsLink != userData.snsLink) {
      updatedData.snsLink = data.snsLink;
    }
    if (data.videoLink != userData.videoLink) {
      updatedData.videoLink = data.videoLink;
    }
    if (data.playStyle != userData.playStyle) {
      updatedData.playStyle = data.playStyle;
    }
    if (data.comment != userData.comment) {
      updatedData.comment = data.comment;
    }
    if (data.age != userData.age) {
      updatedData.age = data.age;
    }
    if (data.sex != userData.sex) {
      updatedData.sex = data.sex;
    }
    if (data.public != userData.public) {
      updatedData.public = data.public;
    }

    if (Object.keys(updatedData).length !== 0) {
      await updateDoc(doc(docRef, currentUser?.uid), updatedData);
      updateUserData!();
    }

    //ホームに戻る
    navigate("/");
  };

  return (
    <Stack
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      sx={{ m: 2, width: "800", mt: 8 }}
    >
      <Grid container direction="column" alignItems="center">
        <Card
          sx={{
            minWidth: 275,
            maxWidth: maxWidth,
            width: "95%",
            minHeight: 600,
            m: 1,
            p: 1,
          }}
        >
          <Typography variant="body1">プロフィール</Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={3}
              sx={{ width: "80%", maxWidth: 400 }}
            >
              <Controller
                name="age"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    sx={{ width: "100%", maxWidth: 200 }}
                  >
                    <InputLabel id="label-reki">年代</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ""}
                      onChange={field.onChange}
                      variant="standard"
                      labelId="label-reki"
                    >
                      <MenuItem value="---">---</MenuItem>
                      <MenuItem value="１０代以下">１０代以下</MenuItem>
                      <MenuItem value="２０代">２０代</MenuItem>
                      <MenuItem value="３０代">３０代</MenuItem>
                      <MenuItem value="４０代">４０代</MenuItem>
                      <MenuItem value="５０代">５０代</MenuItem>
                      <MenuItem value="５０代以上">６０代以上</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="sex"
                control={control}
                render={({ field }) => (
                  <FormControl
                    variant="standard"
                    sx={{ width: "80%", maxWidth: 400 }}
                  >
                    <InputLabel id="label-reki">性別</InputLabel>
                    <Select
                      {...field}
                      value={field.value || ""}
                      onChange={field.onChange}
                      variant="standard"
                      labelId="label-reki"
                    >
                      <MenuItem value="---">---</MenuItem>
                      <MenuItem value="男性">男性</MenuItem>
                      <MenuItem value="女性">女性</MenuItem>
                      <MenuItem value="その他">その他</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Stack>
            <Controller
              name="experience"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  sx={{ width: "80%", maxWidth: 400, mt: 1 }}
                >
                  <InputLabel id="label-reki">卓球歴</InputLabel>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                    labelId="label-reki"
                  >
                    <MenuItem value="---">---</MenuItem>
                    <MenuItem value="３年未満">３年未満</MenuItem>
                    <MenuItem value="３年以上">３年以上</MenuItem>
                    <MenuItem value="５年以上">５年以上</MenuItem>
                    <MenuItem value="１０年以上">１０年以上</MenuItem>
                    <MenuItem value="２０年以上">２０年以上</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <Controller
              name="playStyle"
              control={control}
              rules={validationRules.playStyle}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="プレイスタイル"
                  margin="normal"
                  variant="standard"
                  size="small"
                  placeholder="ドライブマン、カットマン"
                  error={errors.playStyle ? true : false}
                  helperText={errors.playStyle?.message as string}
                  sx={{ width: "80%", maxWidth: 400, mt: 1 }}
                />
              )}
            />
            <Controller
              name="baseCity"
              control={control}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  sx={{ width: "80%", maxWidth: 400, mt: 1 }}
                >
                  <InputLabel id="label-city">活動拠点</InputLabel>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                    labelId="label-city"
                  >
                    <MenuItem value="">---</MenuItem>
                    {prefectures.map((prefecture) => (
                      <MenuItem key={prefecture} value={prefecture}>
                        {prefecture}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="base"
              control={control}
              rules={validationRules.base}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  label="所属、その他活動エリアなど"
                  variant="standard"
                  size="small"
                  placeholder="○○卓球場、○○クラブ..etc"
                  error={errors.base ? true : false}
                  helperText={errors.base?.message as string}
                  sx={{ width: "80%", maxWidth: 400, mt: 1 }}
                />
              )}
            />
            <Controller
              name="comment"
              control={control}
              rules={validationRules.comment}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  multiline
                  rows={4} // 4行に設定していますが、適宜変更してください
                  label="ひとこと"
                  variant="outlined"
                  // size="small"
                  error={errors.comment ? true : false}
                  sx={{ width: "80%", maxWidth: 400, mt: 3 }}
                />
              )}
            />
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "80%" }}
            >
              <Controller
                name="snsLink"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="SNS リンク"
                    variant="standard"
                    size="small"
                    sx={{ width: "80%", maxWidth: 360, mt: 1 }}
                  />
                )}
              />
              <IconButton
                sx={{ mt: 2, width: "20%", maxWidth: 40 }}
                onClick={() => {
                  setValue("snsLink", "未設定");
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
            <Typography sx={{ fontSize: 8 }}>
              ※SNSやホームページのリンクを設定できます
            </Typography>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "80%" }}
            >
              <Controller
                name="videoLink"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    label="YouTube URL"
                    variant="standard"
                    size="small"
                    sx={{ width: "80%", maxWidth: 360, mt: 1 }}
                  />
                )}
              />
              <IconButton
                sx={{ mt: 2, width: "20%", maxWidth: 40 }}
                onClick={() => {
                  setValue("videoLink", "未設定");
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
            <Typography sx={{ fontSize: 8 }}>
              ※リンク共有から取得できるURLを入力してください
            </Typography>
            <Controller
              name="public"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      color="primary"
                      {...(userData.public ? { defaultChecked: true } : {})}
                    />
                  }
                  label="公開する"
                  labelPlacement="start"
                />
              )}
            />
            <Stack direction="column" spacing={0} sx={{ mt: 5, width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ width: "100%" }}
                >
                  変更する
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                  sx={{ width: "100%" }}
                >
                  キャンセル
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </Stack>
  );
}

export default EditProfile;
