import {
  Stack,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { format, parseISO } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db } from "../../components/firebase/firebase";
import { collection, doc, updateDoc, setDoc } from "firebase/firestore";
import { useAuth } from "../../components/firebase/authContext";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import getMainMaxWidth from "../../function/GetMainMaxWidth";

type Inputs = {
  raket: string;
  rDate: any;
  fRubber: string;
  fDate: any;
  bRubber: string;
  bDate: any;
  raketType: string;
  fColor: string;
  fAtusa: string;
  bColor: string;
  bAtusa: string;
};

function EditProfileRaket() {
  const maxWidth = getMainMaxWidth();
  const navigate = useNavigate();
  const { currentUser, userData, updateUserData } = useAuth();
  const [isOtherControllerVisible, setOtherControllerVisible] = useState(true);

  const colorOptions = [
    { value: "#000000", color: "#000000" },
    { value: "#FF0000", color: "#FF0000" },
    { value: "#1e90ff", color: "#1e90ff" },
    { value: "#00ff00", color: "#00ff00" },
    { value: "#ffc0cb", color: "#ffc0cb" },
  ];
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };

  const defaultRDate = userData.rDate ? dayjs(userData.rDate) : null;
  const defaultfDate = userData.rDate ? dayjs(userData.fDate) : null;
  const defaultbDate = userData.rDate ? dayjs(userData.bDate) : null;
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    // デフォルト値、　nameで設定する
    defaultValues: {
      raket: userData.raket,
      rDate: defaultRDate,
      fRubber: userData.fRubber,
      fDate: defaultfDate,
      bRubber: userData.bRubber,
      bDate: defaultbDate,
      raketType: userData.raketType,
      fColor: userData.fColor,
      bColor: userData.bColor,
      fAtusa: userData.fAtusa,
      bAtusa: userData.bAtusa,
    },
  });

  //画面上部をデフォルトに
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validationRules = {
    raket: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 12, message: "12文字以下で入力してください。" },
    },
    rubber: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 12, message: "12文字以下で入力してください。" },
    },
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log(data);

    const submitData = [];

    const docRef = collection(db, "users");
    const updatedData: Record<string, string> = {};

    if (data.bRubber != userData.bRubber) {
      updatedData.bRubber = data.bRubber;
    }
    if (data.fRubber != userData.fRubber) {
      updatedData.fRubber = data.fRubber;
    }
    if (data.fDate.format("YYYY/MM/DD") != userData.fDate) {
      updatedData.fDate = data.fDate.format("YYYY/MM/DD");
    }
    if (data.bDate.format("YYYY/MM/DD") != userData.bDate) {
      updatedData.bDate = data.bDate.format("YYYY/MM/DD");
    }
    if (data.raket != userData.raket) {
      updatedData.raket = data.raket;
    }
    if (data.rDate.format("YYYY/MM/DD") != userData.rDate) {
      updatedData.rDate = data.rDate.format("YYYY/MM/DD");
    }
    if (data.raketType != userData.raketType) {
      updatedData.raketType = data.raketType;
    }
    if (data.fColor != userData.fColor) {
      updatedData.fColor = data.fColor;
    }
    if (data.bColor != userData.bColor) {
      updatedData.bColor = data.bColor;
    }
    if (data.fAtusa != userData.fAtusa) {
      updatedData.fAtusa = data.fAtusa;
    }
    if (data.bAtusa != userData.bAtusa) {
      updatedData.bAtusa = data.bAtusa;
    }

    if (Object.keys(updatedData).length !== 0) {
      await updateDoc(doc(docRef, currentUser?.uid), updatedData);
      updateUserData!();
    }

    navigate("/");
  };

  return (
    <div>
      <Stack
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        sx={{ m: 2, width: "800", mt: 8 }}
      >
        <Grid container direction="column" alignItems="center">
          <Card
            sx={{
              minWidth: 275,
              maxWidth: maxWidth,
              width: "95%",
              minHeight: 600,
              m: 1,
              p: 2,
            }}
          >
            <Typography variant="body1">用具</Typography>
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Stack direction="column" spacing={0} sx={{ mt: 5 }}>
                <Typography sx={{ ...cellLeftStyle, textAlign: "left" }}>
                  フォア
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Controller
                    name="fRubber"
                    control={control}
                    rules={validationRules.rubber}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="フォアラバー"
                        // margin="normal"
                        variant="standard"
                        placeholder="未入力は変更されません"
                        error={errors.fRubber !== undefined}
                        helperText={errors.fRubber?.message}
                      />
                    )}
                  />

                  <Controller
                    name="fDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          {...field}
                          label="変更日"
                          slotProps={{
                            textField: {
                              variant: "standard",
                              // margin: "normal",
                            },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Controller
                    name="fColor"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        sx={{ width: "100%", maxWidth: 200 }}
                      >
                        <InputLabel id="label-fColor">フォア色</InputLabel>
                        <Select
                          {...field}
                          value={field.value || ""}
                          onChange={field.onChange}
                          variant="standard"
                          labelId="label-fColor"
                        >
                          {colorOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              <div
                                style={{
                                  width: "40px",
                                  height: "20px",
                                  backgroundColor: option.color,
                                }}
                              ></div>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Controller
                    name="fAtusa"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        variant="standard"
                        sx={{ width: "100%", maxWidth: 200 }}
                      >
                        <InputLabel id="label-type">厚さ</InputLabel>
                        <Select
                          {...field}
                          value={field.value || ""}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                          variant="standard"
                          labelId="label-raketType"
                        >
                          <MenuItem value="特厚">特厚</MenuItem>
                          <MenuItem value="厚">厚</MenuItem>
                          <MenuItem value="中">中</MenuItem>
                          <MenuItem value="薄">薄</MenuItem>
                          <MenuItem value="極薄">極薄</MenuItem>
                          <MenuItem value="一枚">一枚</MenuItem>
                          <MenuItem value="未設定">未設定</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                </Stack>
              </Stack>
              {isOtherControllerVisible && (
                <Stack direction="column" spacing={0} sx={{ mt: 5 }}>
                  <Typography sx={{ ...cellLeftStyle, textAlign: "left" }}>
                    バック
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Controller
                      name="bRubber"
                      control={control}
                      rules={validationRules.rubber}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          label="バックラバー"
                          variant="standard"
                          size="small"
                          error={errors.bRubber !== undefined}
                          helperText={errors.bRubber?.message}
                        />
                      )}
                    />
                    <Controller
                      name="bDate"
                      control={control}
                      render={({ field }) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            {...field}
                            label="変更日"
                            slotProps={{ textField: { variant: "standard" } }}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Controller
                      name="bColor"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="standard"
                          sx={{ width: "100%", maxWidth: 200 }}
                        >
                          <InputLabel id="label-fColor">バック色</InputLabel>
                          <Select
                            {...field}
                            value={field.value || ""}
                            onChange={field.onChange}
                            variant="standard"
                            labelId="label-fColor"
                          >
                            {colorOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <div
                                  style={{
                                    width: "40px",
                                    height: "20px",
                                    backgroundColor: option.color,
                                  }}
                                ></div>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="bAtusa"
                      control={control}
                      render={({ field }) => (
                        <FormControl
                          variant="standard"
                          sx={{ width: "100%", maxWidth: 200 }}
                        >
                          <InputLabel id="label-type">厚さ</InputLabel>
                          <Select
                            {...field}
                            value={field.value || ""}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                            variant="standard"
                            labelId="label-raketType"
                          >
                            <MenuItem value="特厚">特厚</MenuItem>
                            <MenuItem value="厚">厚</MenuItem>
                            <MenuItem value="中">中</MenuItem>
                            <MenuItem value="薄">薄</MenuItem>
                            <MenuItem value="極薄">極薄</MenuItem>
                            <MenuItem value="一枚">一枚</MenuItem>
                            <MenuItem value="未設定">未設定</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack direction="column" spacing={0} sx={{ mt: 5 }}>
                <Typography sx={{ ...cellLeftStyle, textAlign: "left" }}>
                  ラケット
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Controller
                    name="raket"
                    control={control}
                    rules={validationRules.raket}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        label="ラケット"
                        variant="standard"
                        error={errors.raket !== undefined}
                        helperText={errors.raket?.message}
                        // size="small"
                      />
                    )}
                  />
                  <Controller
                    name="rDate"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker
                          {...field}
                          label="変更日"
                          slotProps={{
                            textField: { variant: "standard", size: "small" },
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Stack>
                <Controller
                  name="raketType"
                  control={control}
                  render={({ field }) => (
                    <FormControl
                      variant="standard"
                      sx={{ width: "100%", maxWidth: 400 }}
                    >
                      <InputLabel id="label-type">ラケットタイプ</InputLabel>
                      <Select
                        {...field}
                        value={field.value || ""}
                        onChange={(e) => {
                          field.onChange(e);
                          if (e.target.value === "ペン（片面）") {
                            setValue("bRubber", "なし");
                            setOtherControllerVisible(false);
                          } else {
                            setOtherControllerVisible(true);
                          }
                        }}
                        variant="standard"
                        labelId="label-raketType"
                      >
                        <MenuItem value="シェーク">シェーク</MenuItem>
                        <MenuItem value="ペン（両面）">ペン（両面）</MenuItem>
                        <MenuItem value="ペン（片面）">ペン（片面）</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack
                direction="column"
                spacing={0}
                sx={{ mt: 5, width: "100%" }}
              >
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{ width: "100%" }}
                  >
                    変更する
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/");
                      window.scrollTo(0, 0);
                    }}
                    sx={{ width: "100%" }}
                  >
                    キャンセル
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Stack>
    </div>
  );
}

export default EditProfileRaket;
