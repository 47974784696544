import {
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  setPersistence,
  browserSessionPersistence,
  UserCredential,
} from "firebase/auth";

import { createContext, useState, useEffect, useContext } from "react";
import { User } from "@firebase/auth-types";

import { auth, db } from "./firebase";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  runTransaction,
} from "firebase/firestore";

type AuthContextType = {
  currentUser: User | null;
  userData: any;
  login?: () => Promise<UserCredential>;
  logout?: () => Promise<void>;
  updateUserData?: () => Promise<void>;
};

const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userData: null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

type Props = {
  children?: JSX.Element;
};

const AuthProvider = ({ children }: Props): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any | null>(null);
  //const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(true); //　ロードが終わるまで描写を止めたい

  const login = async () => {
    const provider = new GoogleAuthProvider();
    return await signInWithPopup(auth, provider);
  };

  const logout = () => {
    return signOut(auth);
  };

  const updateUserData = async () => {
    if (currentUser) {
      const docRef = doc(db, "users", currentUser.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setUserData(docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } else {
      setUserData(null);
    }
  };

  useEffect(() => {
    setLoading(true);
    return onAuthStateChanged(auth, async (user: any) => {
      setLoading(true);
      if (user) {
        setCurrentUser(user);

        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());

          const userRef = doc(db, "users", user.uid);
          if (user.displayName != docSnap.data().userDisplayName) {
            await updateDoc(userRef, {
              userDisplayName: user.displayName,
            });
          }

          if (user.photoURL != docSnap.data().userPhotoURL) {
            await updateDoc(userRef, {
              userPhotoURL: user.photoURL!,
            });
          }
          setUserData(docSnap.data());
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          // ユーザー登録　numをゲット
          var userId = await assignUserID();
          // 新しいユーザードキュメントを追加する
          const newUserRef = doc(db, "users", user.uid);
          await setDoc(newUserRef, {
            userId: userId,
            userDisplayName: user.displayName,
            userPhotoURL: user.photoURL!,
            rate: 1500,
          });
          console.log("New user document added!");

          // 追加した新しいユーザードキュメントのデータを取得してセットする
          const newUserDocSnap = await getDoc(newUserRef);
          setUserData(newUserDocSnap.data());
        }
        setLoading(false);
      } else {
        setCurrentUser(null);
        setUserData(null);
        setLoading(false);
      }
    });
  }, []);

  const assignUserID = async () => {
    try {
      const result = await runTransaction(db, async (transaction) => {
        const usersInfoDoc = doc(db, "users", "usersInfo");
        const usersInfoDocSnap = await transaction.get(usersInfoDoc);

        const currentUserId = usersInfoDocSnap?.data()?.currentUserNum;
        const newUserId = currentUserId + 1;

        transaction.update(usersInfoDoc, { currentUserNum: newUserId });

        return newUserId;
      });
      console.log("New UserID:", result);
      return result;
    } catch (e) {
      console.error("Error creating new room:", e);
    }
  };

  const value: AuthContextType = {
    currentUser,
    userData,
    login,
    logout,
    updateUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
