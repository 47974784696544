import * as React from "react";
import Grid from "@mui/material/Grid"; // Grid version 1
import Card from "@mui/material/Card";

export default function TermsOfServices() {
  return (
    <Grid container direction="column" alignItems="center">
      <Card
        sx={{
          minWidth: 275,
          maxWidth: 800,
          width: "95%",
          minHeight: 600,
          p: 5,
        }}
      >
        <div>
          <h1>利用規約</h1>
          <p>
            本利用規約（以下「本規約」といいます。）は、プログラミング教室Willow（以下「当社」といいます。）が提供するウェブアプリ「タッキュウだいすき」（以下「本サービス」といいます。）の提供条件及び当社と本サービスを利用する全ての当事者（以下「利用者」といいます。）との間の権利義務関係が定められています。本サービスをご利用いただく際には、本規約をお読みいただき、本規約及び当社が定める本サービスに関する各種の規約・規定にご同意いただく必要があります。
          </p>
          <h2>第1条（適用）</h2>
          <div>
            <ul>
              <li>
                本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、本サービスの利用に関わる一切の関係に適用されます。
              </li>
              <li>
                当社が別途定める利用ルール、ガイドライン、マニュアル等につきましては、本規約の一部を構成します。
              </li>
              <li>
                本規約の内容と、前項のガイドライン等その他との説明が異なる場合には、本規約の規定が優先して適用されます。
              </li>
              <li>
                本ソフトウェアには、オープンソースライセンス下に置かれているソフトウェアまたはこれを改変したもの（以下「オープンソースソフトウェア」といいます。）が含まれる場合があります。オープンソースソフトウェアは、かかるオープンソースソフトウェアに適用されるライセンス（以下「オープンソースライセンス」といいます。）の条件に従ってライセンスが付与されます。本規約は、各オープンソースライセンスに基づく利用者の権利を制限するものではなく、それに代わる権利を付与するものでもありません。本規約と各オープンソースライセンスとで矛盾する内容が規定されている場合、矛盾する箇所に限り、該当するオープンソースライセンスの内容が優先して適用されます。
              </li>
            </ul>
          </div>
          <h2>第2条（定義）</h2>
          <div>
            <p>本規約において使用する以下の用語は、各々以下の通りとします。</p>
            <ul>
              <li>
                「本サービス」とは、当社が開発・運営する卓球ポータルサイト「タッキュウだいすき」をいいます。
              </li>
              <li>
                「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権、ドメイン、その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
              </li>
            </ul>
          </div>
          <h2>第3条（利用者）</h2>
          <div>
            <ul>
              <li>
                当社は、利用者が以下の各号のいずれかの事由に該当する場合には、本サービスの提供を行わない事があります。
                <ul>
                  <li>
                    利用者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合
                  </li>
                  <li>
                    反社会的勢力等（暴力団、暴力団員、それらに準ずる団体・企業、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に関与していると当社が判断した場合
                  </li>
                  <li>
                    特定の政治・政党・宗教等の勧誘目的で使用するおそれがあると当社が判断した場合
                  </li>
                  <li>その他、当社が利用を適当でないと判断した場合</li>
                </ul>
              </li>
            </ul>
          </div>
          <h2>第4条（禁止事項）</h2>
          <div>
            <p>
              利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為を行ってはいけません。
            </p>
            <ul>
              <li>
                法律、法令等に違反する、又はそれを助長、誘引、勧誘する行為
              </li>
              <li>
                本サービスの逆アセンブル、逆コンパイル、リバースエンジニアリング、その他本サービスウェブサイトのソースコード、構造、アイデア等を解析するような行為
              </li>
              <li>他の利用者、当社又は第三者に不利益、損害を与える行為</li>
              <li>
                他の利用者又は第三者を差別、誹謗中傷、他者の名誉または信用を毀損する行為
              </li>
              <li>公序良俗に反する行為</li>
              <li>
                本サービスのネットワークまたはシステム等に過度な負荷をかける行為
              </li>
              <li>本サービスの運営を妨害するおそれのある行為</li>
              <li>
                当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
              </li>
              <li>当社の承認なく本サービスに関連して営利を目的とする行為</li>
              <li>反社会的勢力等への利益供与</li>
              <li>
                行為が前各号のいずれかに該当することを知りつつ、その行為を助長する行為
              </li>
              <li>その他、当社が不適切と判断する行為</li>
            </ul>
          </div>
          <h2>第5条（本サービスの停止等）</h2>
          <div>
            <ul>
              <li>
                当社は、以下のいずれかに該当する場合には、事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができます。
                <ul>
                  <li>
                    本サービスに係るコンピューター・システムの点検または保守作業を行う場合
                  </li>
                  <li>通信回線、システム等が事故により停止した場合</li>
                  <li>
                    地震、落雷、火災、風水害、停電、天災地変などにより本サービスの運営ができなくなった場合
                  </li>
                  <li>その他、当社が停止または中断を必要と判断した場合</li>
                </ul>
              </li>
              <li>
                当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。
              </li>
            </ul>
          </div>
          <h2>第6条（権利帰属）</h2>
          <div>
            <ul>
              <li>
                本サービスに関する一切の知的財産権は全て当社に帰属します。
              </li>
              <li>
                当社は本サービスの利用に関するデータの情報を加工・販売することがあります。
              </li>
            </ul>
          </div>
          <h2>第7条（本サービスの内容の変更、終了）</h2>
          <div>
            <ul>
              <li>
                当社は、本サービスの内容を変更し、または提供を終了することができます。
              </li>
              <li>
                当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を負いません。
              </li>
            </ul>
          </div>
          <h2>第8条（免責）</h2>
          <div>
            <ul>
              <li>
                当社は、本サービスの閲覧・利用によって直接的・間接的に生じた損害に対して、弊社は一切の責任を負わないものとします。
              </li>
              <li>
                当社は、本サービスの内容により発生あるいは誘発された損害、情報の利用により生じた事項、またはその情報自体の合法性や適正性等について、一切の責任を負わないものとします。
              </li>
              <li>
                当社は、本サービス提供のための通信回線、システム等の障害等による電子メールの遅配、未配、本サイト上の誤表示及びそれ以外のいかなる原因に基づき利用者に生じた損害についても、賠償する義務を一切負わないものとします。
              </li>
              <li>
                本サービスに掲載されている情報の内容に関しては万全を期しておりますが、その内容の正確性及び安全性を保証するものではありません。
              </li>
              <li>
                本サービスに掲載されている情報については、予告なしに変更又は削除する場合がありますのであらかじめご了承ください。
              </li>
              <li>
                当社は本規約にもとづき、合理的な範囲で各利用者ごとに本サービスの提供範囲の制限を、設定または変更することがあります。
              </li>
            </ul>
          </div>
          <h2>第9条（本規約等の変更）</h2>
          <div>
            <p>
              当社は、本規約を事前の予告や通知なく変更できるものとします。当社は、本規約を変更した場合には、当社所定の方法で当該変更内容を通知するものとします。
            </p>
          </div>
          <h2>第10条（損害賠償）</h2>
          <div>
            <ul>
              <li>
                利用者が本サービスの利用によって他の利用者や第三者に対して損害を与えた場合、利用者は自己の責任と費用をもって解決しなければなりません。
              </li>
              <li>
                利用者が本規約に反した行為、または不正もしくは違法な行為によって当社に損害を与えた場合、当社は当該利用者に対して相応の損害賠償の請求を行なうことができるものとします。
              </li>
            </ul>
          </div>
          <h2>第11条（分離可能性）</h2>
          <div>
            <p>
              本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
            </p>
          </div>
          <h2>第12条（準拠法及び管轄裁判所）</h2>
          <div>
            <ul>
              <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
              <li>
                本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
              </li>
            </ul>
          </div>
          <div>令和3年1月1日 制定</div>
        </div>
      </Card>
    </Grid>
  );
}
