export const origin = "https://pingpong-love.yuyu-log.com/";

export const tochigiCity = [
  "宇都宮市",
  "足利市",
  "栃木市",
  "佐野市",
  "鹿沼市",
  "日光市",
  "小山市",
  "真岡市",
  "大田原市",
  "矢板市",
  "那須塩原市",
  "さくら市",
  "那須烏山市",
  "下野市",
  "上三川町",
  "益子町",
  "茂木町",
  "市貝町",
  "芳賀町",
  "壬生町",
  "野木町",
  "塩谷町",
  "高根沢町",
  "那須町",
  "那珂川町",
  "栃木県内",
  "栃木県北",
  "栃木県央",
  "栃木県南",
  "関東圏",
  "関東圏外",
  "---",
];

// const prefectures = [
//   "北海道",
//   "青森県",
//   "岩手県",
//   "宮城県",
//   "秋田県",
//   "山形県",
//   "福島県",
//   "茨城県",
//   "栃木県",
//   "群馬県",
//   "埼玉県",
//   "千葉県",
//   "東京都",
//   "神奈川県",
//   "新潟県",
//   "富山県",
//   "石川県",
//   "福井県",
//   "山梨県",
//   "長野県",
//   "岐阜県",
//   "静岡県",
//   "愛知県",
//   "三重県",
//   "滋賀県",
//   "京都府",
//   "大阪府",
//   "兵庫県",
//   "奈良県",
//   "和歌山県",
//   "鳥取県",
//   "島根県",
//   "岡山県",
//   "広島県",
//   "山口県",
//   "徳島県",
//   "香川県",
//   "愛媛県",
//   "高知県",
//   "福岡県",
//   "佐賀県",
//   "長崎県",
//   "熊本県",
//   "大分県",
//   "宮崎県",
//   "鹿児島県",
//   "沖縄県",
// ];
