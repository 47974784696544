import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { footerHeightpx } from "../styleTs/styleTs";
import { useLocation } from "react-router-dom";

const Footer: React.FC = () => {
  const location = useLocation();
  if (location.pathname == "/score-counter") {
    return <div></div>;
  }
  return (
    <Box
      sx={{
        bgcolor: "#1976d2",
        display: "flex",
        height: footerHeightpx,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        background: "#37a59a",
        zIndex: 999,
      }}
    >
      <Container maxWidth="sm">
        <Typography
          variant="subtitle1"
          color="white"
          sx={{ fontSize: 10, m: 1 }}
        >
          <Link href="/terms-of-service" color="white" underline="hover">
            利用規約
          </Link>
          <Link
            href="/privacy-policy"
            color="white"
            underline="hover"
            sx={{ ml: 1 }}
          >
            プライバシーポリシー
          </Link>
          {/* <br></br> */}
          　&copy; {new Date().getFullYear()}
          プログラミング教室Willow
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;
