import { BrowserQRCodeReader } from "@zxing/browser";
import React, { FC, useEffect, useRef, useState } from "react";
import Result from "@zxing/library/esm/core/Result";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import Button from "@mui/material/Button/Button";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography/Typography";
import Box from "@mui/material/Box";
import { maxWidth, footerHeight, qrBackButton } from "../../styleTs/styleTs";
import AppBarSpace from "../../components/AppBarSpace";
import BackIcon from "../../img/icons/ic_back.svg";

type QrCodeReaderProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRead: (result: Result) => void;
};

type CameraDeviceInfo = {
  id: string;
  name: string;
};

export const QrCodeReader: FC<QrCodeReaderProps> = ({ onRead, setOpen }) => {
  const theme = useTheme();
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const mountedRef = useRef<boolean>(false);
  const [devices, setDevices] = useState<CameraDeviceInfo[]>([]);
  const [currentCamera, setCurrentCamera] = useState<string | undefined>(
    undefined
  );

  const setDevicesList = async (): Promise<CameraDeviceInfo[]> => {
    const list = await BrowserQRCodeReader.listVideoInputDevices();
    const result: CameraDeviceInfo[] = [];
    for (const device of list) {
      result.push({ id: device.deviceId, name: device.label });
    }
    setDevices([...result]);
    return result;
  };

  useEffect(() => {
    mountedRef.current = true;
    const codeReader = new BrowserQRCodeReader(undefined, undefined);
    setDevicesList();
    codeReader.decodeFromVideoDevice(
      currentCamera,
      videoRef.current!,
      function (result, _, controls) {
        if (mountedRef.current === false) {
          controls.stop();
          return;
        }
        if (typeof result !== "undefined") {
          controls.stop();
          onRead(result);
        }
      }
    );
    return function cleanup() {
      mountedRef.current = false;
    };
  }, [currentCamera]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: `calc(100vh - ${footerHeight}px)`,
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        zIndex: 100,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          maxWidth: `${maxWidth}px`,
          position: "relative",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "top",
          overflow: "hidden",
        }}
      >
        {/* <AppBarSpace /> */}
        <video
          style={{
            height: "100%",
            borderRadius: "1rem",
          }}
          ref={videoRef}
        />

        <div
          style={{
            position: "absolute",
            top: "20%",
            width: "250px",
            height: "250px",
            border: "5px dashed #aaaaaa",
          }}
        ></div>

        <div
          style={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            height: "10%",
            background: "#37a59a",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 100,
          }}
        >
          <Button style={qrBackButton} onClick={() => setOpen(false)}>
            <img src={BackIcon} alt="" width={16} height={16} />
            <p style={{ marginLeft: 10 }}>戻る</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
