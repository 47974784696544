import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged, setPersistence, browserSessionPersistence } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDkn5X_0smzcIpcWqfh1TorcoA19XyIakg",
  authDomain: "t-portal-8fb3c.firebaseapp.com",
  projectId: "t-portal-8fb3c",
  storageBucket: "t-portal-8fb3c.appspot.com",
  messagingSenderId: "688127029735",
  appId: "1:688127029735:web:26d65afc2361b002054d42",
  measurementId: "G-394RZ58W0J"
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setPersistence(getAuth(), browserSessionPersistence)
export const db = getFirestore(app);
export const auth = getAuth();