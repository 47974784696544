// ts
import React from "react";
import YouTube, { YouTubeProps } from "react-youtube";
import { origin } from "../../config";

interface Props {
  videoId: string;
}

export default function Youtube(props: Props) {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      origin: origin,
    },
  };

  return (
    <div className="youtube-wrapper">
      <YouTube videoId={props.videoId} opts={opts} onReady={onPlayerReady} />
    </div>
  );
}
