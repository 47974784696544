import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import React, { FC, useEffect, useRef, useState } from "react";
import { useAuth } from "../../components/firebase/authContext";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useSpring, animated } from "react-spring";
import {
  signInButtonStyle,
  signInButtoninnerDivStyle,
  boldTextStyle,
  maxWidth,
  footerHeightpx,
} from "../../styleTs/styleTs";
import { GoogleIconWhite } from "../../img/icons/svgIcons";
import topTableImg from "../../../src/img/top.jpg";
import logo from "../../../src/img/top_logo.svg";
import { Console } from "console";
import RemoveIcon from "@mui/icons-material/Remove";
import minusIcon from "../../../src/img/icons/stat_minus_2.svg";
import swapIcon from "../../../src/img/icons/change_coat.svg";

function ScoreCounterPage() {
  const [leftPoint, setLeftPoint] = React.useState(0);
  const [leftSetpoint, setLeftSetpoint] = React.useState(0);
  const [rightPoint, setRightPoint] = React.useState(0);
  const [rightSetpoint, setRightSetpoint] = React.useState(0);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const pointStyle = {
    position: "absolute" as const,
    width: "25%",
    height: "70%",
    background: "black",
    fontSize: "18vmax",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    cursor: "pointer",
    borderRadius: "10%",
    boxShadow: "1px 5px 0px rgb(100,100,100)",
    userSelect: "none" as const,
  };

  const pointStyle90deg = {
    position: "absolute" as const,
    width: "70%",
    height: "25%",
    background: "black",
    fontSize: "18vmax",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    cursor: "pointer",
    borderRadius: "10%",
    boxShadow: "1px 5px 0px rgb(100,100,100)",
    userSelect: "none" as const,
  };

  const setpointStyle = {
    position: "absolute" as const,
    width: "15%",
    height: "40%",
    background: "black",
    color: "white",
    fontSize: "13vmax",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    cursor: "pointer",
    borderRadius: "10%",
    boxShadow: "1px 5px 0px rgb(100,100,100)",
    userSelect: "none" as const,
  };

  const setpointStyle90deg = {
    position: "absolute" as const,
    width: "40%",
    height: "15%",
    background: "black",
    color: "white",
    fontSize: "13vmax",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial, sans-serif",
    cursor: "pointer",
    borderRadius: "10%",
    boxShadow: "1px 5px 0px rgb(100,100,100)",
    userSelect: "none" as const,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      {windowSize.width - windowSize.height > 0 ? (
        <div
          style={{
            width: "100%",
            height: `${windowSize.width * 0.5}px`,
            background: "#4169e1",
            position: "relative",
          }}
        >
          <div
            style={{
              ...pointStyle,
              color: leftPoint >= 10 ? "orange" : "white",
              top: "5%",
              left: "4%",
            }}
            onClick={() => {
              setLeftPoint(leftPoint + 1);
            }}
          >
            {leftPoint}
          </div>
          <div
            style={{
              ...setpointStyle,
              top: "5%",
              left: "33%",
            }}
            onClick={() => {
              setLeftSetpoint(leftSetpoint + 1);
            }}
          >
            {leftSetpoint}
          </div>
          <div
            style={{
              ...pointStyle,
              color: rightPoint >= 10 ? "orange" : "white",
              top: "5%",
              right: "4%",
            }}
            onClick={() => {
              setRightPoint(rightPoint + 1);
            }}
          >
            {rightPoint}
          </div>
          <div
            style={{
              ...setpointStyle,
              top: "5%",
              right: "33%",
            }}
            onClick={() => {
              setRightSetpoint(rightSetpoint + 1);
            }}
          >
            {rightSetpoint}
          </div>
          {/* マイナスボタン */}
          <div
            style={{
              position: "absolute",
              top: "75%",
              left: "4%",
              width: "25%",
              height: "20%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setLeftPoint(leftPoint - 1);
            }}
          >
            <img src={minusIcon} alt="" height="80%" />
          </div>
          <div
            style={{
              position: "absolute",
              top: "75%",
              right: "4%",
              width: "25%",
              height: "20%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => {
              setRightPoint(rightPoint - 1);
            }}
          >
            <img src={minusIcon} alt="" height="80%" />
          </div>
          <div
            style={{
              position: "absolute",
              top: "45%",
              left: "33%",
              width: "15%",
              height: "20%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setLeftSetpoint(leftSetpoint - 1);
            }}
          >
            <img src={minusIcon} alt="" height="60%" />
          </div>
          <div
            style={{
              position: "absolute",
              top: "45%",
              right: "33%",
              width: "15%",
              height: "20%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setRightSetpoint(rightSetpoint - 1);
            }}
          >
            <img src={minusIcon} alt="" height="60%" />
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "3%",
              right: "33%",
              width: "15%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3vmax",
              color: "orange",
              background: "rgba(0, 0, 0, 0.2)",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setLeftPoint(0);
              setRightPoint(0);
            }}
          >
            Reset
          </div>
          <div
            style={{
              position: "absolute",
              bottom: "3%",
              left: "33%",
              width: "15%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.2)",
              userSelect: "none" as const,
            }}
            onClick={() => {
              const templeftPoint = leftPoint;
              const temprightPoint = rightPoint;
              const templeftSetpoint = leftSetpoint;
              const temprightsetpoint = rightSetpoint;

              setLeftPoint(temprightPoint);
              setRightPoint(templeftPoint);
              setLeftSetpoint(temprightsetpoint);
              setRightSetpoint(templeftSetpoint);
            }}
          >
            <img src={swapIcon} alt="" height="60%" style={{ fill: "white" }} />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: `${windowSize.height * 0.6}px`,
            height: "100%",
            background: "#4169e1",
            position: "relative",
          }}
        >
          <div
            style={{
              ...pointStyle90deg,
              color: leftPoint >= 10 ? "orange" : "white",
              top: "4%",
              right: "5%",
            }}
            onClick={() => {
              setLeftPoint(leftPoint + 1);
            }}
          >
            <div style={{ transform: "rotate(90deg)" }}>{leftPoint}</div>
          </div>
          <div
            style={{
              ...setpointStyle90deg,
              top: "33%",
              right: "5%",
            }}
            onClick={() => {
              setLeftSetpoint(leftSetpoint + 1);
            }}
          >
            <div style={{ transform: "rotate(90deg)" }}>{leftSetpoint}</div>
          </div>
          <div
            style={{
              ...pointStyle90deg,
              color: rightPoint >= 10 ? "orange" : "white",
              bottom: "4%",
              right: "5%",
            }}
            onClick={() => {
              setRightPoint(rightPoint + 1);
            }}
          >
            <div style={{ transform: "rotate(90deg)" }}>{rightPoint}</div>
          </div>
          <div
            style={{
              ...setpointStyle90deg,
              bottom: "33%",
              right: "5%",
            }}
            onClick={() => {
              setRightSetpoint(rightSetpoint + 1);
            }}
          >
            <div style={{ transform: "rotate(90deg)" }}>{rightSetpoint}</div>
          </div>
          {/* マイナスボタン */}
          <div
            style={{
              position: "absolute",
              right: "75%",
              top: "4%",
              width: "20%",
              height: "25%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setLeftPoint(leftPoint - 1);
            }}
          >
            <img
              src={minusIcon}
              alt=""
              height="80%"
              style={{ transform: "rotate(90deg)" }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: "75%",
              bottom: "4%",
              width: "20%",
              height: "25%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setRightPoint(rightPoint - 1);
            }}
          >
            <img
              src={minusIcon}
              alt=""
              height="80%"
              style={{ transform: "rotate(90deg)" }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: "45%",
              top: "33%",
              width: "20%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setLeftSetpoint(leftSetpoint - 1);
            }}
          >
            <img
              src={minusIcon}
              alt=""
              height="60%"
              style={{ transform: "rotate(90deg)" }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              right: "45%",
              bottom: "33%",
              width: "20%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setRightSetpoint(rightSetpoint - 1);
            }}
          >
            <img
              src={minusIcon}
              alt=""
              height="60%"
              style={{ transform: "rotate(90deg)" }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: "3%",
              bottom: "33%",
              width: "15%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "3vmax",
              color: "orange",
              background: "rgba(0, 0, 0, 0.2)",
              userSelect: "none" as const,
            }}
            onClick={() => {
              setLeftPoint(0);
              setRightPoint(0);
            }}
          >
            <div style={{ transform: "rotate(90deg)" }}>Reset</div>
          </div>
          <div
            style={{
              position: "absolute",
              left: "3%",
              top: "33%",
              width: "15%",
              height: "15%",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.2)",
              userSelect: "none" as const,
            }}
            onClick={() => {
              const templeftPoint = leftPoint;
              const temprightPoint = rightPoint;
              const templeftSetpoint = leftSetpoint;
              const temprightsetpoint = rightSetpoint;

              setLeftPoint(temprightPoint);
              setRightPoint(templeftPoint);
              setLeftSetpoint(temprightsetpoint);
              setRightSetpoint(templeftSetpoint);
            }}
          >
            <img
              src={swapIcon}
              alt=""
              height="60%"
              style={{ fill: "white", transform: "rotate(90deg)" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default ScoreCounterPage;
