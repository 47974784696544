import Youtube from "../../components/youtube/youtube";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import React, { FC, useEffect, useRef, useState, forwardRef } from "react";
import { QrCodeReader } from "../components/QrCodeReader";
import { useAuth } from "../../components/firebase/authContext";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AppBarSpace from "../../components/AppBarSpace";
import FooterSpace from "../../components/FooterSpace";
import {
  heading1Style,
  editButtonStyle,
  grayEllipseStyle,
  infoText,
  maxWidth,
  commentArea,
  grayEllipseLinkStyle,
} from "../../styleTs/styleTs";
import editIcon from "../../../src/img/icons/ic_edit.svg";
import linkIcon from "../../../src/img/icons/ic_link.svg";
import youtubeIcon from "../../../src/img/icons/ic_youtube.svg";
import EditProfileModal from "../editProfile/editProfileModal";
import EditRaketProfileModal from "../editProfile/editRaketProfileModal";
import shakeRaket from "../../../src/img/shake_racket.svg";
import penRaket from "../../../src/img/pen_racket.svg";
import penRaket2 from "../../../src/img/pen2_racket.svg";

function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

const skewedLeftGrayBlockStyle = (
  top: number,
  width: string,
  height: number
): React.CSSProperties => ({
  position: "absolute",
  top: top,
  left: 0,
  width: width,
  height: `${height}px`,
  backgroundColor: "#e5e5e5",
  zIndex: 100,
  clipPath: `polygon(0 0, 100% 0, calc(100% - ${0.6 * height}px) 100%, 0 100%)`,
});

const skewedRightBlockStyle = (
  top: number,
  width: string,
  height: number
): React.CSSProperties => ({
  position: "absolute",
  top: top,
  right: 0,
  width: width,
  height: `${height}px`,
  backgroundColor: "#27a5bb",
  zIndex: 100,
  clipPath: `polygon(${0.6 * height}px 0, 100% 0,  100% 100%, 0 100%)`,
});

function Home() {
  const [isOpenQRCamera, setIsOpenQRCamera] = useState<boolean>(false);
  const [qrResult, setQrResult] = useState<any>({});
  const { currentUser, userData } = useAuth();

  const clickOpenQrReader = () => {
    setIsOpenQRCamera(true);
  };
  // ラケットの表示等
  const typographyStyle1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -80%)",
    width: "90%",
    lineHeight: 1, // 行間を狭くする
  };

  // テーブルの見出のスタイル
  const cellLeftStyle = {
    border: "none",
    fontSize: 12,
    fontWeight: "bold",
    color: "gray",
  };
  // ラケット情報
  const cellRightStyle = {
    border: "none",
    fontSize: 14,
    fontWeight: "bold",
    color: "black",
    width: "100%",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    margin: "3px",
  };
  const [editProfileModalopen, setEditProfileModalopen] = useState(false);
  const handleEditProfileModalOpen = () => setEditProfileModalopen(true);
  const handleEditProfileModalClose = () => setEditProfileModalopen(false);

  const [editRaketProfileModalopen, setEditRaketProfileModalopen] =
    useState(false);
  const handleEditRaketProfileModalOpen = () =>
    setEditRaketProfileModalopen(true);
  const handleEditRaketProfileModalClose = () =>
    setEditRaketProfileModalopen(false);

  const HtmlTooltip = styled(
    ({ className, bdColor, ...props }: TooltipProps & { bdColor?: string }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    )
  )(({ theme, bdColor = "#37a59a" }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 1)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `5px solid ${bdColor}`,
    },
  }));

  return (
    <div>
      {/* worningがでるが影響なさそうなので無視。モーダル内のコンポーネントのref関係*/}
      <Modal open={editProfileModalopen} onClose={handleEditProfileModalClose}>
        <EditProfileModal onClose={handleEditProfileModalClose} />
      </Modal>
      <Modal
        open={editRaketProfileModalopen}
        onClose={handleEditRaketProfileModalClose}
      >
        <EditRaketProfileModal onClose={handleEditRaketProfileModalClose} />
      </Modal>
      {currentUser ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: `${maxWidth}px`,
            }}
          >
            <AppBarSpace />
            <div
              style={{
                height: "30px",
                position: "relative",
              }}
            >
              <div style={skewedLeftGrayBlockStyle(0, "90%", 20)}></div>
              <div style={skewedRightBlockStyle(10, "45%", 20)}></div>
            </div>
            <Box sx={{ pt: 3, pl: 3, pr: 3, pb: 3 }}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  mb: 3,
                  width: "100%",
                  maxWidth: maxWidth,
                }}
              >
                <Avatar
                  alt="myPhoto"
                  src={currentUser.photoURL!}
                  sx={{
                    width: 100,
                    height: 100,
                    border: "4px solid #37a59a", // 縁の設定
                  }}
                />
                <Stack
                  direction="column"
                  spacing={0.8}
                  sx={{ m: 1, width: "100%", maxWidth: maxWidth }}
                >
                  <div style={grayEllipseStyle}>
                    {"No." + String(userData?.userId).padStart(5, "0")}
                  </div>
                  <div
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      textAlign: "left",
                      padding: "5px 0 0 30px",
                    }}
                  >
                    {currentUser.displayName}
                  </div>
                  <Divider />
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{ alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "100px",
                        borderRadius: "30px",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "13px",
                        background: "#37a59a",
                        padding: "2px 0 2px 0",
                      }}
                    >
                      レート
                    </div>
                    <div
                      style={{
                        width: "80px",
                        color: "#37a59a",
                        fontWeight: "bold",
                        fontSize: "18px",
                        padding: "0",
                      }}
                    >
                      {userData?.rate ?? ""}
                    </div>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ width: "100%", maxWidth: maxWidth, height: "2rem" }}
              >
                <div style={heading1Style}>
                  <p style={{ margin: 0, textAlign: "center" }}>ラケット情報</p>
                </div>
                <Button
                  variant="contained"
                  sx={editButtonStyle}
                  onClick={handleEditRaketProfileModalOpen}
                >
                  <img src={editIcon} alt="" width={15} height={15} />
                </Button>
              </Stack>
              {/* ラケット情報 */}
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{ mt: 2, mb: 0 }}
                direction="row"
              >
                <div
                  style={{
                    width: "100px",
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      userData?.raketType === "シェーク"
                        ? shakeRaket
                        : userData?.raketType === "ペン（両面）"
                        ? penRaket
                        : userData?.raketType === "ペン（片面）"
                        ? penRaket2
                        : undefined
                    }
                    alt="Raket Type"
                    style={{ maxWidth: "80%", maxHeight: "80%" }}
                  />
                </div>

                <Stack
                  style={{
                    width: "calc(100% - 100px)",
                    height: "100px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    background: "#e5e5e5",
                    borderRadius: "0.5rem",
                    padding: "0.5rem 1rem 0.5rem 1rem",
                  }}
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p>{userData?.raket}</p>
                        <p>変更日： {userData?.rDate ?? ""}</p>
                        <p>タイプ： {userData?.raketType ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      ラケット：
                      {userData?.raket ?? ""}
                    </Typography>
                  </HtmlTooltip>
                  <HtmlTooltip
                    bdColor={userData?.fColor}
                    title={
                      <React.Fragment>
                        <p>{userData?.fRubber}</p>
                        <p>変更日： {userData?.fDate ?? ""}</p>
                        <p>厚さ： {userData?.fAtusa ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      フォア　：
                      {userData?.fRubber ?? ""}
                    </Typography>
                  </HtmlTooltip>
                  <HtmlTooltip
                    bdColor={userData?.bColor}
                    title={
                      <React.Fragment>
                        <p>{userData?.bRubber}</p>
                        <p>変更日： {userData?.bDate ?? ""}</p>
                        <p>厚さ： {userData?.bAtusa ?? ""}</p>
                      </React.Fragment>
                    }
                    placement="top"
                  >
                    <Typography
                      sx={{
                        ...cellRightStyle,
                      }}
                    >
                      バック　：
                      {userData?.bRubber ?? ""}
                    </Typography>
                  </HtmlTooltip>
                </Stack>
              </Stack>
              <Typography
                sx={{
                  border: "none",
                  fontSize: 12,
                  color: "gray",
                  width: "90%",
                  textAlign: "right",
                  // marginRight: "5rem",
                }}
              >
                ※長押しで詳細を確認
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  width: "100%",
                  maxWidth: maxWidth,
                  height: "2rem",
                  marginBottom: 2,
                }}
              >
                <div style={heading1Style}>
                  <p style={{ margin: 0, textAlign: "center" }}>基本情報</p>
                </div>
                <Button
                  variant="contained"
                  sx={editButtonStyle}
                  onClick={handleEditProfileModalOpen}
                >
                  <img src={editIcon} alt="" width={15} height={15} />
                </Button>
              </Stack>
              <Stack
                direction="column"
                spacing={1.5}
                sx={{ width: "100%", maxWidth: maxWidth }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>年代</div>
                  <div style={infoText}> {userData?.age ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>性別</div>
                  <div style={infoText}>　{userData?.sex ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>卓球歴</div>
                  <div style={infoText}> {userData?.experience ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>戦型</div>
                  <div style={infoText}> {userData?.playStyle ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>活動エリア</div>
                  <div style={infoText}>{userData?.base ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>所属・拠点</div>
                  <div style={infoText}>{userData?.baseCity ?? ""}</div>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{ alignItems: "center" }}
                >
                  <div style={grayEllipseStyle}>コメント</div>
                </Stack>
                <div style={commentArea}>{userData?.comment ?? ""}</div>
                {userData?.snsLink && userData?.snsLink !== "未設定" && (
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: "center" }}
                  >
                    <img src={linkIcon} alt="" width={15} height={15} />
                    <div style={grayEllipseLinkStyle}>
                      {isValidUrl(userData?.snsLink) ? (
                        <a
                          href={userData?.snsLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#37a59a" }}
                        >
                          {userData?.snsLink}
                        </a>
                      ) : (
                        "無効なリンク"
                      )}
                    </div>
                  </Stack>
                )}
                {userData?.videoLink && userData?.videoLink !== "未設定" && (
                  <div>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{ alignItems: "center", mb: 1 }}
                    >
                      <img src={youtubeIcon} alt="" width={15} height={15} />
                      <div style={grayEllipseLinkStyle}>
                        {isValidUrl(userData?.snsLink) ? (
                          <a
                            href={userData?.videoLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#37a59a" }}
                          >
                            {userData?.videoLink}
                          </a>
                        ) : (
                          "無効なリンク"
                        )}
                      </div>
                    </Stack>
                    <Youtube
                      videoId={userData?.videoLink?.substr(
                        userData.videoLink.lastIndexOf("/") + 1
                      )}
                    />
                  </div>
                )}
              </Stack>
            </Box>
          </Box>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Home;
