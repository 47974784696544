import {
  Stack,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db } from "../../components/firebase/firebase";
import { collection, doc, updateDoc, setDoc } from "firebase/firestore";
import { useAuth } from "../../components/firebase/authContext";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import getMainMaxWidth from "../../function/GetMainMaxWidth";
import { tochigiCity } from "../../config";
import { maxWidth } from "../../styleTs/styleTs";
import {
  editProfileKoumoku,
  editProfileCancel,
  editProfileSubmit,
  heading1Style,
} from "../../styleTs/styleTs";
import shakeBgColor from "../../../src/img/shake_bg_color.svg";
import checkIcon from "../../../src/img/icons/ic_check.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import styles from "../../styleTs/style.module.css";
import { useEffect, useState } from "react";

type Inputs = {
  raket: string;
  Gender: string;
  rDate: any;
  fRubber: string;
  fDate: any;
  bRubber: string;
  bDate: any;
  raketType: string;
  fColor: string;
  fAtusa: string;
  bColor: string;
  bAtusa: string;
};

const colorOptions = [
  { value: "#000000", color: "#000000" },
  { value: "#FF0000", color: "#FF0000" },
  { value: "#1e90ff", color: "#1e90ff" },
  { value: "#00ff00", color: "#00ff00" },
  { value: "#ffc0cb", color: "#ffc0cb" },
];

function EditRaketProfileModal(props: any) {
  const handleClose = () => {
    props.onClose();
  };
  const navigate = useNavigate();
  const { currentUser, userData, updateUserData } = useAuth();
  const [isOtherControllerVisible, setOtherControllerVisible] = useState(true);

  const defaultRDate = userData.rDate ? dayjs(userData.rDate) : null;
  const defaultfDate = userData.rDate ? dayjs(userData.fDate) : null;
  const defaultbDate = userData.rDate ? dayjs(userData.bDate) : null;

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    // デフォルト値、　nameで設定する
    defaultValues: {
      raket: userData.raket,
      rDate: defaultRDate,
      fRubber: userData.fRubber,
      fDate: defaultfDate,
      bRubber: userData.bRubber,
      bDate: defaultbDate,
      raketType: userData.raketType,
      fColor: userData.fColor,
      bColor: userData.bColor,
      fAtusa: userData.fAtusa,
      bAtusa: userData.bAtusa,
    },
  });

  const selectedFColor = watch("fColor");
  const selectedBColor = watch("bColor");

  const validationRules = {
    raket: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 12, message: "12文字以下で入力してください。" },
    },
    rubber: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 12, message: "12文字以下で入力してください。" },
    },
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log(data);

    const submitData = [];

    const docRef = collection(db, "users");
    const updatedData: Record<string, string> = {};

    if (data.bRubber != userData.bRubber) {
      updatedData.bRubber = data.bRubber;
    }
    if (data.fRubber != userData.fRubber) {
      updatedData.fRubber = data.fRubber;
    }
    if (data.fDate.format("YYYY/MM/DD") != userData.fDate) {
      updatedData.fDate = data.fDate.format("YYYY/MM/DD");
    }
    if (data.bDate.format("YYYY/MM/DD") != userData.bDate) {
      updatedData.bDate = data.bDate.format("YYYY/MM/DD");
    }
    if (data.raket != userData.raket) {
      updatedData.raket = data.raket;
    }
    if (data.rDate.format("YYYY/MM/DD") != userData.rDate) {
      updatedData.rDate = data.rDate.format("YYYY/MM/DD");
    }
    if (data.raketType != userData.raketType) {
      updatedData.raketType = data.raketType;
    }
    if (data.fColor != userData.fColor) {
      updatedData.fColor = data.fColor;
    }
    if (data.bColor != userData.bColor) {
      updatedData.bColor = data.bColor;
    }
    if (data.fAtusa != userData.fAtusa) {
      updatedData.fAtusa = data.fAtusa;
    }
    if (data.bAtusa != userData.bAtusa) {
      updatedData.bAtusa = data.bAtusa;
    }

    if (Object.keys(updatedData).length !== 0) {
      await updateDoc(doc(docRef, currentUser?.uid), updatedData);
      updateUserData!();
    }

    //ホームに戻る
    handleClose();
  };
  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: `${maxWidth * 0.8}px`,
          height: "80%",
          background: "white",
          borderRadius: " 1rem",
        }}
      >
        {/* タイトル */}
        <div
          style={{
            display: "flex",
            background: "#37a59a",
            height: "10%",
            borderRadius: " 1rem 1rem 0 0",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          ラケット情報編集
        </div>
        {/* 中 */}
        <div
          style={{
            background: "white",
            height: "75%",
            overflowY: "auto",
          }}
        >
          <Stack
            // justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ m: 2 }}
          >
            {/* ラケット */}
            <div
              style={{ width: "100%", display: "flex", alignItems: "center" }}
            >
              <div style={heading1Style}>
                <p style={{ margin: 0, textAlign: "center" }}>ラケット</p>
              </div>
            </div>

            <div style={editProfileKoumoku}>ラケット名</div>
            <Controller
              name="raket"
              control={control}
              rules={validationRules.raket}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="standard"
                  error={errors.raket !== undefined}
                  helperText={errors.raket?.message}
                  sx={{ width: "90%" }}
                  // size="small"
                />
              )}
            />

            <div style={editProfileKoumoku}>変更日</div>
            <Controller
              name="rDate"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    {...field}
                    slotProps={{
                      textField: { variant: "standard", size: "small" },
                    }}
                    sx={{ width: "90%" }}
                  />
                </LocalizationProvider>
              )}
            />

            <div style={editProfileKoumoku}>ラケットタイプ</div>
            <Controller
              name="raketType"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e);
                      if (e.target.value === "ペン（片面）") {
                        setValue("bRubber", "なし");
                        setOtherControllerVisible(false);
                      } else {
                        setOtherControllerVisible(true);
                      }
                    }}
                    variant="standard"
                    labelId="label-raketType"
                  >
                    <MenuItem value="シェーク">シェーク</MenuItem>
                    <MenuItem value="ペン（両面）">ペン（両面）</MenuItem>
                    <MenuItem value="ペン（片面）">ペン（片面）</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            {/* フォアラバー */}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <div style={heading1Style}>
                <p style={{ margin: 0, textAlign: "center" }}>フォアラバー</p>
              </div>
            </div>
            <div style={editProfileKoumoku}>ラバー名</div>
            <Controller
              name="fRubber"
              control={control}
              rules={validationRules.rubber}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="standard"
                  error={errors.fRubber !== undefined}
                  helperText={errors.fRubber?.message}
                  sx={{ width: "90%" }}
                />
              )}
            />

            <div style={editProfileKoumoku}>厚さ</div>
            <Controller
              name="fAtusa"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    variant="standard"
                    labelId="label-raketType"
                  >
                    <MenuItem value="特厚">特厚</MenuItem>
                    <MenuItem value="厚">厚</MenuItem>
                    <MenuItem value="中">中</MenuItem>
                    <MenuItem value="薄">薄</MenuItem>
                    <MenuItem value="極薄">極薄</MenuItem>
                    <MenuItem value="一枚">一枚</MenuItem>
                    <MenuItem value="未設定">未設定</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <div style={editProfileKoumoku}>カラー</div>
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ width: "100%" }}
              direction="row"
            >
              <div
                style={{
                  width: "30%",
                  height: "80px",
                  background: selectedFColor || "#e5e5e5",
                }}
              >
                <img
                  src={shakeBgColor}
                  alt="User Photo"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div
                style={{
                  width: "60%",
                  height: "80px",
                  background: "#e5e5e5",
                  borderRadius: "0.5rem",
                }}
              >
                <Controller
                  name="fColor"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>{radioButtonColorGroup(field, "fColor")}</div>
                  )}
                />
              </div>
            </Stack>

            <div style={editProfileKoumoku}>変更日</div>
            <Controller
              name="fDate"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    {...field}
                    sx={{ width: "90%" }}
                    slotProps={{
                      textField: {
                        variant: "standard",
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />

            {/* バックラバー */}
            <div
              style={{
                marginTop: "2rem",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={heading1Style}>
                <p style={{ margin: 0, textAlign: "center" }}>バックラバー</p>
              </div>
            </div>
            <div style={editProfileKoumoku}>ラバー名</div>
            <Controller
              name="bRubber"
              control={control}
              rules={validationRules.rubber}
              render={({ field }) => (
                <TextField
                  {...field}
                  style={{ marginTop: "0.5rem" }}
                  sx={{ width: "90%" }}
                  type="text"
                  variant="standard"
                  size="small"
                  error={errors.bRubber !== undefined}
                  helperText={errors.bRubber?.message}
                />
              )}
            />

            <div style={editProfileKoumoku}>厚さ</div>
            <Controller
              name="bAtusa"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={(e) => {
                      field.onChange(e);
                    }}
                    variant="standard"
                    labelId="label-raketType"
                  >
                    <MenuItem value="特厚">特厚</MenuItem>
                    <MenuItem value="厚">厚</MenuItem>
                    <MenuItem value="中">中</MenuItem>
                    <MenuItem value="薄">薄</MenuItem>
                    <MenuItem value="極薄">極薄</MenuItem>
                    <MenuItem value="一枚">一枚</MenuItem>
                    <MenuItem value="未設定">未設定</MenuItem>
                  </Select>
                </FormControl>
              )}
            />

            <div style={editProfileKoumoku}>カラー</div>
            <Stack
              alignItems="center"
              justifyContent="center"
              spacing={1}
              sx={{ width: "100%" }}
              direction="row"
            >
              <div
                style={{
                  width: "30%",
                  height: "80px",
                  background: selectedBColor || "#e5e5e5",
                }}
              >
                <img
                  src={shakeBgColor}
                  alt="User Photo"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
              <div
                style={{
                  width: "60%",
                  height: "80px",
                  background: "#e5e5e5",
                  borderRadius: "0.5rem",
                }}
              >
                <Controller
                  name="bColor"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>{radioButtonColorGroup(field, "bColor")}</div>
                  )}
                />
              </div>
            </Stack>

            <div style={editProfileKoumoku}>変更日</div>
            <Controller
              name="bDate"
              control={control}
              render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    {...field}
                    slotProps={{ textField: { variant: "standard" } }}
                    sx={{ width: "90%" }}
                  />
                </LocalizationProvider>
              )}
            />
          </Stack>
        </div>
        {/* ボタンエリア */}
        <div
          style={{
            background: "white",
            height: "15%",
            borderRadius: "0 0 1rem 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid #AAAAAA",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              background: "bule",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={editProfileCancel}
            >
              キャンセル
            </Button>
            <Button
              // onClick={}
              variant="contained"
              type="submit"
              sx={editProfileSubmit}
            >
              変更
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
}

const radioButtonColorGroup = (field: any, name: string) => {
  return (
    <div>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
        direction="row"
      >
        {radioButtonColor(field, name, colorOptions[0].color)}
        {radioButtonColor(field, name, colorOptions[1].color)}
        {radioButtonColor(field, name, colorOptions[2].color)}
      </Stack>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%" }}
        direction="row"
      >
        {radioButtonColor(field, name, colorOptions[3].color)}
        {radioButtonColor(field, name, colorOptions[4].color)}
        {/* {radioButtonColor(
                          field,
                          "fColor",
                          colorOptions[5].color
                        )} */}
      </Stack>
    </div>
  );
};

const radioButtonColor = (field: any, name: string, color: string) => {
  return (
    <div
      style={{
        width: "3rem",
        height: "2.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "1.5rem",
        border: field.value === color ? "2px solid #37a59a" : "none",
      }}
    >
      <input
        type="radio"
        name={name}
        value={color}
        checked={field.value === color}
        onChange={() => field.onChange(color)}
        id={name + color}
        style={{
          display: "none",
        }}
      />
      <label
        htmlFor={name + color}
        style={{
          width: "2.3rem",
          height: "1.8rem",
          borderRadius: "1rem",
          border: "2px solid white",
          background: `${color}`,
          boxShadow: "0px 2px 0px rgba(150,150,150, 1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={checkIcon}
          alt=""
          width={15}
          height={15}
          style={{ display: field.value === color ? "block" : "none" }}
        />
      </label>
    </div>
  );
};

export default EditRaketProfileModal;
