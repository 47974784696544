import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import CloseIcon from "@mui/icons-material/Close";
import HomeIcon from "@mui/icons-material/Home";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useAuth } from "../components/firebase/authContext";
import AppBar from "@mui/material/AppBar";
import { FormGroup, SvgIcon } from "@mui/material";
import QRCode from "react-qr-code";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  signOutButtonStyle,
  signInButtonStyle,
  signInButtoninnerDivStyle,
  boldTextStyle,
} from "../styleTs/styleTs";
import { GoogleIconWhite } from "../img/icons/svgIcons";
import ProfileIcon from "../../src/img/icons/ic_profile.svg";
import menberIcon from "../../src/img/icons/ic_team.svg";
import rateIcon from "../../src/img/icons/ic_rate.svg";
import mailIcon from "../../src/img/icons/ic_mail.svg";
import logoAppbar from "../../src/img/top_logo_w.svg";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const GoogleIcon = () => {
  return (
    <SvgIcon>
      <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
          id="Shape"
          fill="#EA4335"
        />
        <path
          d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
          id="Shape"
          fill="#FBBC05"
        />
        <path
          d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
          id="Shape"
          fill="#4285F4"
        />
        <path
          d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
          fill="#34A853"
        />
      </svg>
    </SvgIcon>
  );
};

export const SwordsIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        color="#37a59a"
      >
        <path d="M762-96 645-212l-88 88-28-28q-23-23-23-57t23-57l169-169q23-23 57-23t57 23l28 28-88 88 116 117q12 12 12 28t-12 28l-50 50q-12 12-28 12t-28-12Zm118-628L426-270l5 4q23 23 23 57t-23 57l-28 28-88-88L198-96q-12 12-28 12t-28-12l-50-50q-12-12-12-28t12-28l116-117-88-88 28-28q23-23 57-23t57 23l4 5 454-454h160v160ZM334-583l24-23 23-24-23 24-24 23Zm-56 57L80-724v-160h160l198 198-57 56-174-174h-47v47l174 174-56 57Zm92 199 430-430v-47h-47L323-374l47 47Zm0 0-24-23-23-24 23 24 24 23Z" />
      </svg>
    </SvgIcon>
  );
};

<svg
  xmlns="http://www.w3.org/2000/svg"
  height="24"
  viewBox="0 -960 960 960"
  width="24"
>
  <path d="M762-96 645-212l-88 88-28-28q-23-23-23-57t23-57l169-169q23-23 57-23t57 23l28 28-88 88 116 117q12 12 12 28t-12 28l-50 50q-12 12-28 12t-28-12Zm118-628L426-270l5 4q23 23 23 57t-23 57l-28 28-88-88L198-96q-12 12-28 12t-28-12l-50-50q-12-12-12-28t12-28l116-117-88-88 28-28q23-23 57-23t57 23l4 5 454-454h160v160ZM334-583l24-23 23-24-23 24-24 23Zm-56 57L80-724v-160h160l198 198-57 56-174-174h-47v47l174 174-56 57Zm92 199 430-430v-47h-47L323-374l47 47Zm0 0-24-23-23-24 23 24 24 23Z" />
</svg>;

export default function PersistentDrawerLeft() {
  const navigate = useNavigate();
  const { currentUser, login, logout } = useAuth();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const handleClose = () => {
    setOpenBackdrop(false);
  };
  const handleOpen = () => {
    setOpenBackdrop(true);
  };
  const isXsScreen = useMediaQuery("(max-width: 1000px)");

  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      handleClose();
    }
  }, [currentUser]);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const appBarStyle = {
    backgroundColor: "#37a59a",
    borderRadius: "0 0 10px 10px",
    boxShadow: "1px 3px 0px rgba(215,215,215, 1)",
    // boxShadow: "1px 3px 0px rgba(180,180,180, 1)",
    height: "60px",
  };

  const dividerStyle = {
    margin: "auto",
    width: "90%",
  };

  const listitemIconStyle = {
    minWidth: "50px",
  };

  const drowerInnner = () => {
    return (
      <div>
        <DrawerHeader sx={{ justifyContent: "left" }}>
          {isXsScreen && (
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                backgroundColor: "#37a59a",
                borderRadius: "10%",
                padding: 0,
                marginLeft: 1,
              }}
            >
              <CloseIcon sx={{ color: "#ffffff", fontSize: "30px" }} />
            </IconButton>
          )}
        </DrawerHeader>
        <Divider />
        <List sx={{ padding: 0 }}>
          <ListItem>
            <ListItemButton component={Link} to="/" onClick={handleDrawerClose}>
              <ListItemIcon sx={listitemIconStyle}>
                <img src={ProfileIcon} alt="" width={30} height={30} />
              </ListItemIcon>
              <ListItemText
                primary={"プロフィール"}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </ListItem>
          <Divider sx={dividerStyle} />
          <ListItem>
            <ListItemButton
              component={Link}
              to="/userlist"
              onClick={handleDrawerClose}
            >
              <ListItemIcon sx={listitemIconStyle}>
                <img src={menberIcon} alt="" width={30} height={30} />
              </ListItemIcon>
              <ListItemText
                primary={"メンバー"}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </ListItem>
          <Divider sx={dividerStyle} />
          <ListItem>
            <ListItemButton
              component={Link}
              to="/taisen"
              onClick={handleDrawerClose}
            >
              <ListItemIcon sx={listitemIconStyle}>
                <img src={rateIcon} alt="" width={30} height={30} />
              </ListItemIcon>
              <ListItemText
                primary={"レート対戦"}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </ListItem>
          <Divider sx={dividerStyle} />
          <ListItem>
            <ListItemButton
              component={Link}
              target="_blank"
              to="https://yuyu-log.com/contact/"
              onClick={handleDrawerClose}
            >
              <ListItemIcon sx={listitemIconStyle}>
                <img src={mailIcon} alt="" width={30} height={30} />
              </ListItemIcon>
              <ListItemText
                primary={"問い合わせ"}
                primaryTypographyProps={{ fontWeight: "bold" }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider sx={dividerStyle} />
        {currentUser === null ? (
          <div>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}
            ></Box>
          </div>
        ) : (
          <div>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}
            >
              <Button
                onClick={logout}
                variant="contained"
                sx={signOutButtonStyle}
                startIcon={<GoogleIconWhite />}
              >
                <b style={boldTextStyle}>サインアウト</b>
              </Button>
            </Box>
          </div>
        )}
        {/* <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              mt: 2,
              mb: 2,
            }}
          >
            <p style={{ textAlign: "center" }}>リンク集</p>
            <Button
              component={Link}
              to="/score-counter"
              onClick={handleDrawerClose}
              // target="_blank"
            >
              <img src={rateIcon} alt="" width={30} height={30} />
              <Typography variant="body1" fontWeight="bold">
                スコアカウンター
              </Typography>
            </Button>
          </Box>
        </div> */}
      </div>
    );
  };

  //appbarを非表示にするパス
  if (location.pathname == "/login" || location.pathname == "/score-counter") {
    return <div></div>;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* バックドロップ */}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar position="fixed" style={appBarStyle}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              mr: 2,
              ...(open && { display: "none" }),
              position: "absolute",
              left: 20,
            }}
          >
            <MenuIcon sx={{ fontSize: "1.8rem", transform: "scaleY(1.2)" }} />
          </IconButton>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img src={logoAppbar} alt="" height="35px" />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          display: { display: isXsScreen ? "none" : "block" },
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          justifyContent: "space-between",
        }}
        variant="permanent"
        anchor="left"
        open
        ModalProps={{
          BackdropProps: {
            invisible: true, // 背景を暗くしない
          },
        }}
      >
        {drowerInnner()}
      </Drawer>
      <Drawer
        sx={{
          display: { display: isXsScreen ? "block" : "none" },
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          justifyContent: "space-between",
        }}
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drowerInnner()}
      </Drawer>
    </Box>
  );
}
