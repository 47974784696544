import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  runTransaction,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import AppBarSpace from "../../components/AppBarSpace";
import { auth, db } from "../../components/firebase/firebase";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid"; // Grid version 1
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import QRCode from "react-qr-code";
import { useAuth } from "../../components/firebase/authContext";
import calculateNewRatings from "../../function/calculateRating";
import getMainMaxWidth from "../../function/GetMainMaxWidth";
import {
  skewedLeftGrayBlockStyle,
  skewedRightBlockStyle,
  footerHeight,
  maxWidth,
  taisenResultButtonDisable,
  taisenResultButtonActiv,
  qrBackButton,
  editProfileCancel,
  editProfileSubmit,
  resultPlayerBorder,
  resultPlayerSelectedIcon1,
  resultPlayerSelectedIcon2,
  footerBackBtnArea,
} from "../../styleTs/styleTs";
import BackIcon from "../../img/icons/ic_back.svg";
import Modal from "@mui/material/Modal";
import checkIcon from "../../../src/img/icons/ic_check_pink.svg";
import upIcon from "../../../src/img/icons/ic_up.svg";
import downIcon from "../../../src/img/icons/ic_down.svg";

export function TaisenRoom() {
  const { roomId } = useParams();
  const [isHost, setIsHost] = useState<boolean | null>(null);

  // 自分申告済
  const [isResultSeted, setIsResultSeted] = useState<boolean>(false);
  // 両者の結果が申告されたら。
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const { currentUser, userData } = useAuth();

  const [unsubscribe, setUnsubscribe] = useState<any | null>(null);

  const [hostUserId, setHostUserId] = useState(null);
  const [guestUserId, setGuestUserId] = useState(null);
  const [isHostUserIdSeted, setIsHostUserIdSeted] = useState(false);
  const [isGuestUserIdSeted, setIsGuestUserIdSeted] = useState(false);

  const [hostUserResult, setHostUserResult] = useState(null);
  const [guestUserResult, setGuestUserResult] = useState(null);

  const [hostUser, setHostUser] = useState<any | null>(null);
  const [guestUser, setGuestUser] = useState<any | null>(null);

  const [hostNewRate, setHostNewRate] = useState<number | null>(null);
  const [hostPreRate, setHostPreRate] = useState<number | null>(null);
  const [guestNewRate, setGuestNewRate] = useState<number | null>(null);
  const [guestPreRate, setGuestPreRate] = useState<number | null>(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // 選択されている勝者
  const [selectWinner, setSelectWinner] = useState<any>(null);

  const navigate = useNavigate();

  const fetchUserData = async (uid: any, kind: string) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDocSnap = await getDoc(userDocRef);
      console.log("フェッチユーザー");

      if (kind == "host") {
        setHostUser(userDocSnap.data()!!);
      } else {
        setGuestUser(userDocSnap.data()!!);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    const roomIdString = roomId?.toString() || "";
    const roomDocRef = doc(db, "rooms", roomIdString);

    const unsubscribeFunc = onSnapshot(roomDocRef, (doc) => {
      console.log("スナップショット変更");
      const updatedData = doc.data();
      if (updatedData) {
        const updatedHostId = updatedData?.hostUser;
        const updatedGuestId = updatedData?.guestUser;

        setHostUserId(updatedHostId);
        setGuestUserId(updatedGuestId);

        const updateHostResult = updatedData?.hostUserResult;
        const updateGuestResult = updatedData?.guestUserResult;
        setHostUserResult(updateHostResult);
        setGuestUserResult(updateGuestResult);

        const isFinished = updatedData?.finish;
        const hostUserMotoRate = updatedData?.hostUserMotoRate;
        const guestUserMotoRate = updatedData?.guestUserMotoRate;
        const hostUserNewRate = updatedData?.hostNewRate;
        const guestUserNewRate = updatedData?.guestNewRate;

        setHostPreRate(hostUserMotoRate);
        setGuestPreRate(guestUserMotoRate);
        setHostNewRate(hostUserNewRate);
        setGuestNewRate(guestUserNewRate);
        setIsFinished(isFinished);
        if (isFinished) {
          if (unsubscribe) {
            unsubscribe();
          }
        }

        let isHost = null;
        if (currentUser!.uid == updatedData?.hostUser) {
          isHost = true;
        } else {
          isHost = false;
        }
        setIsHost(isHost);
        // 自分が申告済かどうか
        if (isHost) {
          if (updateHostResult) {
            setIsResultSeted(true);
          }
        } else {
          if (updateGuestResult) {
            setIsResultSeted(true);
          }
        }
      } else {
        const path = `/taisen/`;
        navigate(path);
      }
    });

    setUnsubscribe(() => unsubscribeFunc);

    // コンポーネントがアンマウントされたときにリスナーをクリーンアップする
    return () => {
      // リスナーを解除する
      unsubscribeFunc();
    };
  }, []);
  useEffect(() => {
    if (!isHostUserIdSeted) {
      if (hostUserId) {
        fetchUserData(hostUserId, "host");
        setIsHostUserIdSeted(true);
      }
    }
  }, [hostUserId]);

  useEffect(() => {
    if (!isGuestUserIdSeted) {
      //↑の条件は変わったときしか呼び出されないのでこれは不要だったかも
      if (guestUserId) {
        fetchUserData(guestUserId, "guest");
        setIsGuestUserIdSeted(true);
      }
    }
  }, [guestUserId]);

  useEffect(() => {
    if (!isFinished) {
      if (guestUserId && hostUserId) {
        if (isHost) {
          if (hostUserResult !== undefined) {
            if (guestUserResult !== undefined) {
              checkResult(isHost, hostUserResult!, guestUserResult!);
            }
          }
        }
      }
    }
  }, [hostUserResult, guestUserResult, isFinished]);

  const updateRating = (hostNewRating: any, guestNewRating: any) => {
    const hostUid: any = hostUserId;
    const hostDocRef = doc(db, "users", hostUid);
    updateDoc(hostDocRef, { rate: hostNewRating });
    const guestUid: any = guestUserId;
    const guestDocRef = doc(db, "users", guestUid);
    updateDoc(guestDocRef, { rate: guestNewRating });

    const roomIdString = roomId?.toString() || "";
    const roomDocRef = doc(db, "rooms", roomIdString);
    updateDoc(roomDocRef, {
      finish: true,
      hostNewRate: hostNewRating,
      guestNewRate: guestNewRating,
    });
  };

  const resultProcess = (winUser: String) => {
    let rateobj;
    if (winUser === "host") {
      rateobj = calculateNewRatings(hostUser.rate, guestUser.rate);
      updateRating(rateobj.newWinnerRating, rateobj.newLoserRating);
    } else {
      rateobj = calculateNewRatings(guestUser.rate, hostUser.rate);
      updateRating(rateobj.newLoserRating, rateobj.newWinnerRating);
    }
  };

  // 結果不一致の場合
  const resultProcessFuitti = () => {
    const roomIdString = roomId?.toString() || "";
    const roomDocRef = doc(db, "rooms", roomIdString);
    updateDoc(roomDocRef, {
      finish: true,
    });
  };

  const checkResult = (
    ishost: boolean | null,
    hostUserResult: string,
    guestUserResult: string
  ) => {
    if (ishost) {
      if (guestUserResult) {
        if (guestUserResult === hostUserResult) {
          // subscribe();
          alert("結果は一致しています");
          resultProcess(hostUserResult!);
        } else {
          alert("結果が不一致です。");
          resultProcessFuitti();
        }
      } else {
        alert("相手の入力をお待ちください。");
      }
    } else {
      if (hostUserResult) {
        if (hostUserResult === guestUserResult) {
          // subscribe();
          alert("結果は一致しています");
        } else {
          alert("結果が不一致です。");
        }
      } else {
        alert("相手の入力をお待ちください。");
      }
    }
  };

  const setResult = async () => {
    // subscribe();
    const roomIdString = roomId?.toString() || "";
    const roomDocRef = doc(db, "rooms", roomIdString);
    var winUser = selectWinner;
    if (!winUser) {
      return;
    }

    if (winUser === "host") {
      if (isHost) {
        await updateDoc(roomDocRef, { hostUserResult: "host" });
      } else {
        await updateDoc(roomDocRef, { guestUserResult: "host" });
      }
    } else if (winUser === "guest") {
      if (isHost) {
        await updateDoc(roomDocRef, { hostUserResult: "guest" });
      } else {
        await updateDoc(roomDocRef, { guestUserResult: "guest" });
      }
    }
    handleClose();
  };

  const LoadingScreen = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // グレーの半透明背景
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000, // 他の要素よりも前面に表示
        }}
      >
        <div style={{ color: "white" }}>対戦相手の入力をお待ちください...</div>
      </div>
    );
  };

  const GuestView = () => {
    return (
      <div
        style={{
          width: "150px",
          height: "190px",
          background: "white",
          borderRadius: " 1rem",
          border: "3px solid #37a59a",
          boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
        }}
      >
        <div
          style={{
            display: "flex",
            background: "#37a59a",
            height: "25px",
            borderRadius: " 0.5rem 0.5rem 0 0",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center" as const,
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          ルーム参加者
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "3px",
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            sx={{ pb: 1 }}
          >
            <div
              style={{
                fontSize: 12,
                fontWeight: "bold",
                marginTop: "2px",
              }}
            >
              {guestUser?.userDisplayName ?? "募集中"}
            </div>
            {guestUser && guestUser.userPhotoURL ? (
              <Avatar
                alt="guestPhoto"
                src={guestUser.userPhotoURL!}
                sx={{
                  width: 60,
                  height: 60,
                  border: "3px solid #37a59a", // 縁の設定
                }}
              />
            ) : (
              <AccountCircleIcon style={{ fontSize: "60px", color: "#333" }} />
            )}
            <div
              style={{
                width: "80px",
                borderRadius: "30px",
                color: "white",
                fontWeight: "bold",
                fontSize: "10px",
                background: "#37a59a",
                padding: "2px 0 2px 0",
                zIndex: 10,
              }}
            >
              レート
            </div>
            <div
              style={{
                width: "120px",
                height: "40px",
                borderRadius: "5px",
                color: "#37a59a",
                fontWeight: "bold",
                fontSize: "14px",
                background: "#e5e5e5",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "-10px",
                paddingTop: "5px",
              }}
            >
              {isFinished ? (
                <div>
                  <span style={{ color: "gray", fontSize: "smaller" }}>
                    {guestPreRate}
                  </span>
                  →
                  {guestPreRate! < guestNewRate! ? (
                    <span style={{ color: "#37a59a" }}>
                      {guestNewRate}
                      <img src={upIcon} alt="" width={10} height={10} />
                    </span>
                  ) : (
                    <span style={{ color: "#de8d8d" }}>
                      {guestNewRate}
                      <img src={downIcon} alt="" width={10} height={10} />
                    </span>
                  )}
                </div>
              ) : (
                guestUser?.rate ?? ""
              )}
            </div>
          </Stack>
        </div>
      </div>
    );
  };

  const GuestViewWait = () => {
    return (
      <div
        style={{
          width: "150px",
          height: "190px",
          background: "white",
          borderRadius: " 1rem",
          border: "3px solid #919191",
          boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
        }}
      >
        <div
          style={{
            display: "flex",
            background: "#919191",
            height: "25px",
            borderRadius: " 0.5rem 0.5rem 0 0",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center" as const,
            color: "white",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          ルーム参加者
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "3px",
            height: "160px",
          }}
        >
          <div
            style={{
              width: "80%",
              height: "50px",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "black",
              fontSize: "10px",
              background: "#e5e5e5",
            }}
          >
            対戦相手を
            <br />
            募集中...
          </div>
        </div>
      </div>
    );
  };

  const Win = () => {
    return (
      <div
        style={{
          width: "150px",
          height: "30px",
          position: "relative", // 親要素を相対位置に設定
        }}
      >
        <span
          style={{
            position: "absolute",
            bottom: "0px",
            left: "50%",
            transform: "translateX(-50%)",
            height: "10px",
            width: "70px",
            backgroundColor: "#d6edf2",
            borderRadius: " 1rem",
          }}
        ></span>
        <span
          style={{
            position: "absolute", // 絶対位置指定
            bottom: "-4px", // 下端を親要素の下端に合わせる
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            fontSize: "25px",
            fontWeight: "bold",
            color: "#37a59a",
          }}
        >
          WIN!
        </span>
      </div>
    );
  };

  const Lose = () => {
    return (
      <div
        style={{
          width: "150px",
          height: "30px",
          position: "relative", // 親要素を相対位置に設定
        }}
      >
        <span
          style={{
            position: "absolute",
            bottom: "0px",
            left: "50%",
            transform: "translateX(-50%)",
            height: "10px",
            width: "70px",
            backgroundColor: "#e5e5e5",
            borderRadius: " 1rem",
          }}
        ></span>
        <span
          style={{
            position: "absolute", // 絶対位置指定
            bottom: "0px", // 下端を親要素の下端に合わせる
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
            fontSize: "16px",
            fontWeight: "bold",
            color: "gray",
          }}
        >
          LOSE...
        </span>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: `calc(100vh - 70px - ${footerHeight}px)`,
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: `${maxWidth}px`,
          }}
        >
          <AppBarSpace />
          <div
            style={{
              marginTop: "20px",
              height: "60px",
              position: "relative",
              marginBottom: "5px",
            }}
          >
            <div style={skewedLeftGrayBlockStyle(0, "90%", 50)}>対戦ルーム</div>
            <div style={skewedRightBlockStyle(30, "45%", 30)}></div>
          </div>
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              maxWidth: maxWidth,
              p: 2,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "130px",
                height: "130px",
                borderRadius: "0.3rem",
                border: "3px solid #37a59a",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100px" }}
                value={roomId?.toString() || ""}
                viewBox={`0 0 256 256`}
              />
            </div>
            <div
              style={{
                width: "130px",
                borderRadius: "30px",
                color: "black",
                fontWeight: "bold",
                fontSize: "13px",
                background: "#e5e5e5",
                padding: "2px 0 2px 0",
              }}
            >
              ルームID：{roomId?.toString()}
            </div>
            {isFinished ? (
              <></>
            ) : (
              <p style={{ fontSize: "10px", fontWeight: "bold" }}>
                対戦相手の方は「ルーム参加」から
                <br />
                上記QRコードを読み込んでください。
              </p>
            )}
          </Stack>
          {/* ルーム作成・参加 */}
          {/* {isFinished ? (
              <></>
            ) : (
              <p style={{ fontSize: "10px", fontWeight: "bold" }}>
                対戦相手の方は「ルーム参加」から
                <br />
                上記QRコードを読み込んでください。
              </p>
            )} */}
          {isFinished ? (
            hostNewRate ? (
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{
                  width: "100%",
                  maxWidth: maxWidth,
                  p: 0,
                }}
              >
                {hostPreRate! < hostNewRate! ? <Win /> : <Lose />}
                {hostPreRate! < hostNewRate! ? <Lose /> : <Win />}
              </Stack>
            ) : (
              <div style={{ color: "red" }}>
                入力された結果が一致していません。<br></br>
                部屋を立て直して再度入力してください。
              </div>
            )
          ) : (
            <></>
          )}
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              maxWidth: maxWidth,
              p: 1,
            }}
          >
            <div
              style={{
                width: "150px",
                height: "190px",
                background: "white",
                borderRadius: " 1rem",
                border: "3px solid #37a59a",
                boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  background: "#37a59a",
                  height: "25px",
                  borderRadius: " 0.5rem 0.5rem 0 0",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center" as const,
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "bold",
                }}
              >
                ルーム作成者
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "3px",
                }}
              >
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  sx={{ pb: 1 }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: "2px",
                    }}
                  >
                    {hostUser?.userDisplayName ?? ""}
                  </div>
                  <Avatar
                    alt="myPhoto"
                    src={hostUser?.userPhotoURL!}
                    sx={{
                      width: 60,
                      height: 60,
                      border: "3px solid #37a59a", // 縁の設定
                    }}
                  />
                  <div
                    style={{
                      width: "80px",
                      borderRadius: "30px",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "10px",
                      background: "#37a59a",
                      padding: "2px 0 2px 0",
                      zIndex: 10,
                    }}
                  >
                    レート
                  </div>
                  <div
                    style={{
                      width: "120px",
                      height: "40px",
                      borderRadius: "5px",
                      color: "#37a59a",
                      fontWeight: "bold",
                      fontSize: "14px",
                      background: "#e5e5e5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "-10px",
                      paddingTop: "5px",
                    }}
                  >
                    {isFinished ? (
                      <div>
                        <span style={{ color: "gray", fontSize: "smaller" }}>
                          {hostPreRate}
                        </span>
                        →
                        {hostPreRate! < hostNewRate! ? (
                          <span style={{ color: "#37a59a" }}>
                            {hostNewRate}
                            <img src={upIcon} alt="" width={10} height={10} />
                          </span>
                        ) : (
                          <span style={{ color: "#de8d8d" }}>
                            {hostNewRate}
                            <img src={downIcon} alt="" width={10} height={10} />
                          </span>
                        )}
                      </div>
                    ) : (
                      hostUser?.rate ?? ""
                    )}
                  </div>
                </Stack>
              </div>
            </div>
            {isGuestUserIdSeted ? <GuestView /> : <GuestViewWait />}
          </Stack>

          {/* 結果報告前後で表示変更 */}
          {isResultSeted ? (
            isFinished ? (
              <Button
                variant="contained"
                style={taisenResultButtonActiv}
                sx={{ m: 1 }}
                component={Link}
                to="/taisen"
              >
                <p style={{ marginLeft: 10 }}>退室して対戦を終了</p>
              </Button>
            ) : (
              <></>
            )
          ) : (
            <Button
              variant="contained"
              onClick={handleOpen}
              style={
                isGuestUserIdSeted
                  ? taisenResultButtonActiv
                  : taisenResultButtonDisable
              }
              disabled={!isGuestUserIdSeted}
              sx={{ m: 1 }}
            >
              <p style={{ marginLeft: 10 }}>対戦結果入力</p>
            </Button>
          )}
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={footerBackBtnArea}>
          <Button style={qrBackButton} component={Link} to="/taisen">
            <img src={BackIcon} alt="" width={16} height={16} />
            <p style={{ marginLeft: 10 }}>戻る</p>
          </Button>
        </div>
      </div>
      {/* 対戦結果報告画面 */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: `${maxWidth * 0.8}px`,
            height: "350px",
            background: "white",
            borderRadius: " 1rem",
          }}
        >
          {/* タイトル */}
          <div
            style={{
              display: "flex",
              background: "#37a59a",
              height: "50px",
              borderRadius: " 1rem 1rem 0 0",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              color: "white",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            対戦結果入力
          </div>
          {/* 中 */}
          <div
            style={{
              background: "white",
              height: "75%",
              overflowY: "auto",
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              direction="column"
              spacing={2}
              sx={{ m: 2 }}
            >
              <p style={{ fontWeight: "bold", fontSize: "smaller" }}>
                勝者を選択してください
              </p>
              <Stack
                justifyContent="center"
                alignItems="center"
                direction="row"
                spacing={2}
                sx={{ m: 2 }}
              >
                {/* ホスト側 */}
                <Stack
                  sx={resultPlayerBorder}
                  style={{ position: "relative" }}
                  spacing={1}
                  onClick={() => setSelectWinner("host")}
                >
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: "2px",
                    }}
                  >
                    {hostUser?.userDisplayName ?? ""}
                  </div>
                  <Avatar
                    alt="myPhoto"
                    src={hostUser?.userPhotoURL!}
                    sx={{
                      width: 70,
                      height: 70,
                      border: "3px solid #37a59a", // 縁の設定
                    }}
                    style={{ marginBottom: "10px" }}
                  />
                  {/* 選択中のアイコン */}
                  <div
                    style={{
                      ...resultPlayerSelectedIcon1,
                      display: selectWinner === "host" ? "flex" : "none",
                    }}
                  >
                    選択中
                  </div>
                  <div
                    style={{
                      ...resultPlayerSelectedIcon2,
                      display: selectWinner === "host" ? "flex" : "none",
                    }}
                  >
                    <img src={checkIcon} alt="" width={28} height={28} />
                  </div>
                </Stack>
                {/* ゲスト側 */}
                <Stack
                  sx={resultPlayerBorder}
                  style={{ position: "relative" }}
                  spacing={1}
                  onClick={() => setSelectWinner("guest")}
                >
                  <div
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      marginTop: "2px",
                    }}
                  >
                    {guestUser?.userDisplayName ?? ""}
                  </div>
                  <Avatar
                    alt="myPhoto"
                    src={guestUser?.userPhotoURL!}
                    sx={{
                      width: 70,
                      height: 70,
                      border: "3px solid #37a59a", // 縁の設定
                    }}
                  />
                  {/* 選択中のアイコン */}
                  <div
                    style={{
                      ...resultPlayerSelectedIcon1,
                      display: selectWinner === "guest" ? "flex" : "none",
                    }}
                  >
                    選択中
                  </div>
                  <div
                    style={{
                      ...resultPlayerSelectedIcon2,
                      display: selectWinner === "guest" ? "flex" : "none",
                    }}
                  >
                    <img src={checkIcon} alt="" width={28} height={28} />
                  </div>
                </Stack>
              </Stack>
            </Stack>
          </div>
          {/* ボタンエリア */}
          <div
            style={{
              background: "white",
              height: "15%",
              borderRadius: "0 0 1rem 1rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderTop: "1px solid #AAAAAA",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
                background: "bule",
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
                sx={editProfileCancel}
              >
                キャンセル
              </Button>
              <Button
                onClick={setResult}
                variant="contained"
                sx={editProfileSubmit}
              >
                決定
              </Button>
            </Stack>
          </div>
        </div>
      </Modal>
      {/* 結果報告前後で表示変更 */}
      {isResultSeted ? isFinished ? <></> : <LoadingScreen /> : <></>}
    </div>
  );
}
