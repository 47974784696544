import {
  Stack,
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { db } from "../../components/firebase/firebase";
import { collection, doc, updateDoc, setDoc } from "firebase/firestore";
import { useAuth } from "../../components/firebase/authContext";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import getMainMaxWidth from "../../function/GetMainMaxWidth";
import { tochigiCity } from "../../config";
import { maxWidth } from "../../styleTs/styleTs";
import {
  editProfileKoumoku,
  editProfileCancel,
  editProfileSubmit,
} from "../../styleTs/styleTs";

type Inputs = {
  age: string;
  sex: string;
  rate: string;
  experience: any;
  playStyle: string;
  baseCity: string;
  base: string;
  comment: any;
  snsLink: string;
  videoLink: any;
  public: boolean;
  // bRubber: string
};

function EditProfileModal(prop: any) {
  const handleClose = () => {
    prop.onClose();
  };
  const navigate = useNavigate();
  const { currentUser, userData, updateUserData } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    // デフォルト値、　nameで設定する
    defaultValues: {
      age: userData.age,
      sex: userData.sex,
      rate: userData.rate,
      experience: userData.experience,
      playStyle: userData.playStyle,
      base: userData.base,
      baseCity: userData.baseCity,
      comment: userData.comment,
      snsLink: userData.snsLink,
      videoLink: userData.videoLink,
      public: userData.public,
    },
  });

  const validationRules = {
    playStyle: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 15, message: "15文字以下で入力してください。" },
    },
    base: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 15, message: "15文字以下で入力してください。" },
    },
    comment: {
      // required: "名前を入力してください。",
      // minLength: { value: 4, message: "4文字以上で入力してください。" },
      maxLength: { value: 200, message: "200文字以下で入力してください。" },
    },
  };

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    console.log(data);

    const submitData = [];

    const docRef = collection(db, "users");
    const updatedData: Record<string, any> = {};

    if (data.rate != userData.rate) {
      updatedData.rate = data.rate;
    }
    if (data.experience != userData.experience) {
      updatedData.experience = data.experience;
    }
    if (data.baseCity != userData.baseCity) {
      updatedData.baseCity = data.baseCity;
    }
    if (data.base != userData.base) {
      updatedData.base = data.base;
    }
    if (data.snsLink != userData.snsLink) {
      updatedData.snsLink = data.snsLink;
    }
    if (data.videoLink != userData.videoLink) {
      updatedData.videoLink = data.videoLink;
    }
    if (data.playStyle != userData.playStyle) {
      updatedData.playStyle = data.playStyle;
    }
    if (data.comment != userData.comment) {
      updatedData.comment = data.comment;
    }
    if (data.age != userData.age) {
      updatedData.age = data.age;
    }
    if (data.sex != userData.sex) {
      updatedData.sex = data.sex;
    }
    if (data.public != userData.public) {
      updatedData.public = data.public;
    }

    if (Object.keys(updatedData).length !== 0) {
      await updateDoc(doc(docRef, currentUser?.uid), updatedData);
      updateUserData!();
    }

    //ホームに戻る
    handleClose();
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: `${maxWidth * 0.8}px`,
          height: "80%",
          background: "white",
          borderRadius: " 1rem",
        }}
      >
        {/* タイトル */}
        <div
          style={{
            display: "flex",
            background: "#37a59a",
            height: "10%",
            borderRadius: " 1rem 1rem 0 0",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          基本情報編集
        </div>
        {/* 中 */}
        <div
          style={{
            background: "white",
            height: "75%",
            overflowY: "auto",
          }}
        >
          <Stack
            // justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ m: 2 }}
          >
            <div style={editProfileKoumoku}>年代</div>
            <Controller
              name="age"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                  >
                    <MenuItem value="---">---</MenuItem>
                    <MenuItem value="１０代以下">１０代以下</MenuItem>
                    <MenuItem value="２０代">２０代</MenuItem>
                    <MenuItem value="３０代">３０代</MenuItem>
                    <MenuItem value="４０代">４０代</MenuItem>
                    <MenuItem value="５０代">５０代</MenuItem>
                    <MenuItem value="５０代以上">６０代以上</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <div style={editProfileKoumoku}>性別</div>
            <Controller
              name="sex"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                  >
                    <MenuItem value="---">---</MenuItem>
                    <MenuItem value="男性">男性</MenuItem>
                    <MenuItem value="女性">女性</MenuItem>
                    <MenuItem value="その他">その他</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <div style={editProfileKoumoku}>卓球歴</div>
            <Controller
              name="experience"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                    labelId="label-reki"
                  >
                    <MenuItem value="---">---</MenuItem>
                    <MenuItem value="３年未満">３年未満</MenuItem>
                    <MenuItem value="３年以上">３年以上</MenuItem>
                    <MenuItem value="５年以上">５年以上</MenuItem>
                    <MenuItem value="１０年以上">１０年以上</MenuItem>
                    <MenuItem value="２０年以上">２０年以上</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <div style={editProfileKoumoku}>プレイスタイル</div>
            <Controller
              name="playStyle"
              control={control}
              rules={validationRules.playStyle}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  margin="normal"
                  variant="standard"
                  size="small"
                  placeholder="ドライブマン、カットマン"
                  error={errors.playStyle ? true : false}
                  helperText={errors.playStyle?.message as string}
                  sx={{ width: "90%" }}
                />
              )}
            />

            <div style={editProfileKoumoku}>活動エリア</div>
            <Controller
              name="baseCity"
              control={control}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ width: "90%" }}>
                  <Select
                    {...field}
                    value={field.value || ""}
                    onChange={field.onChange}
                    variant="standard"
                    labelId="label-city"
                  >
                    <MenuItem value="">---</MenuItem>
                    {tochigiCity.map((prefecture) => (
                      <MenuItem key={prefecture} value={prefecture}>
                        {prefecture}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <div style={editProfileKoumoku}>所属・拠点</div>
            <Controller
              name="base"
              control={control}
              rules={validationRules.base}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  variant="standard"
                  size="small"
                  placeholder="○○卓球場、○○クラブ..etc"
                  error={errors.base ? true : false}
                  helperText={errors.base?.message as string}
                  sx={{ width: "90%" }}
                />
              )}
            />
            <div style={editProfileKoumoku}>コメント</div>
            <Controller
              name="comment"
              control={control}
              rules={validationRules.comment}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="text"
                  multiline
                  rows={4}
                  variant="outlined"
                  // size="small"
                  error={errors.comment ? true : false}
                  sx={{ width: "90%" }}
                />
              )}
            />
            <div style={editProfileKoumoku}>SNSリンク</div>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "90%" }}
            >
              <Controller
                name="snsLink"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="standard"
                    size="small"
                    sx={{ width: "80%", maxWidth: 360, mt: 1 }}
                  />
                )}
              />
              <IconButton
                sx={{ width: "20%", maxWidth: 40 }}
                onClick={() => {
                  setValue("snsLink", "未設定");
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
            <div style={editProfileKoumoku}>Youtubeリンク</div>

            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "90%" }}
            >
              <Controller
                name="videoLink"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="text"
                    variant="standard"
                    size="small"
                    sx={{ width: "80%", maxWidth: 360, mt: 1 }}
                  />
                )}
              />
              <IconButton
                sx={{ width: "20%", maxWidth: 40 }}
                onClick={() => {
                  setValue("videoLink", "未設定");
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
            <Controller
              name="public"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      sx={{
                        "& .MuiSwitch-switchBase.Mui-checked": {
                          color: "#37a57a",
                        },
                        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                          {
                            backgroundColor: "#37a57a",
                          },
                      }}
                      {...(userData.public ? { defaultChecked: true } : {})}
                    />
                  }
                  label="プロフィールを公開する"
                  labelPlacement="start"
                />
              )}
            />
          </Stack>
        </div>
        {/* ボタンエリア */}
        <div
          style={{
            background: "white",
            height: "15%",
            borderRadius: "0 0 1rem 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid #AAAAAA",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              background: "bule",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={editProfileCancel}
            >
              キャンセル
            </Button>
            <Button
              // onClick={}
              variant="contained"
              type="submit"
              sx={editProfileSubmit}
            >
              変更
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
}

export default EditProfileModal;
