import React from "react";

function calculateNewRatings(winnerRating: any, loserRating: any) {
  const kFactor = 32;
  const minChange = 10;
  // const W_ba = kFactor * (loserRating - winnerRating/800 + 0.5) 式展開前
  var W_ba = (kFactor * (loserRating - winnerRating)) / 800 + 0.5 * kFactor;

  W_ba = Math.max(W_ba, minChange);

  const newWinnerRating = winnerRating + W_ba;
  const newLoserRating = loserRating - W_ba;

  return {
    newWinnerRating: Math.round(newWinnerRating), // 新しい勝者のレーティングを四捨五入
    newLoserRating: Math.round(newLoserRating), // 新しい敗者のレーティングを四捨五入
  };
}

export default calculateNewRatings;
