import { SvgIcon } from "@mui/material";

//その他変数
export const maxWidth = 450;
export const maxWidthPx = "450px";
export const footerHeight = 50;
export const footerHeightpx = "50px";
export const appBarHeight = 60;

export const signInButtonStyle = {
  width: 220,
  height: 55,
  padding: "2px !important",
  borderRadius: "30px",
  color: "white",
  boxShadow: "1px 5px 0px rgba(191,191,191, 1)",
  fontSize: "19px",
  background: "-webkit-linear-gradient(top, #caecfd 0%, #2a7f78 100%)",
  "&:hover": {
    boxShadow: "1px 5px 0px rgba(191,191,191, 1)",
    background: "-webkit-linear-gradient(top, #caecfd 0%, #2a7f78 100%)",
  },
};
export const signInButtoninnerDivStyle = {
  width: 220,
  height: 51,
  borderRadius: "30px",
  color: "white",
  backgroundColor: "#37a59a", // 背景
  display: "flex", // ボタン内のアイコンとテキストを横に並べる
  alignItems: "center", // アイコンとテキストを縦方向に中央寄せ
  justifyContent: "center",
};
export const signOutButtonStyle = {
  width: 200,
  height: 45,
  borderRadius: "30px",
  color: "white",
  boxShadow: "1px 3px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  background: "#db6767",
  "&:hover": {
    boxShadow: "1px 3px 0px rgba(191,191,191, 1)",
    background: "#db6767",
  },
};
export const boldTextStyle = {
  margin: "0 0 0 8px", // ボールドテキストにマージンを追加
};
export const heading1Style = {
  borderLeft: "10px solid #000",
  borderColor: "#37a59a",
  paddingLeft: "1rem",
  fontSize: "16px",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const editButtonStyle = {
  width: "30px",
  height: "30px",
  minWidth: "10px",
  minHeight: "10px",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "10px",
  background: "#37a59a",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "#37a59a",
  },
};

export const grayEllipseStyle = {
  width: "100px",
  borderRadius: "30px",
  color: "black",
  fontWeight: "bold",
  fontSize: "13px",
  background: "#e5e5e5",
  padding: "2px 0 2px 0",
  textAlign: "center" as const,
};

export const grayEllipseLinkStyle = {
  width: "100%",
  borderRadius: "30px",
  fontWeight: "bold",
  fontSize: "13px",
  background: "#e5e5e5",
  padding: "2px 20px 2px 20px",
  textAlign: "left" as const,
  overflow: "hidden",
  color: "#37a59a",
  textOverflow: "ellipsis" as const,
  whiteSpace: "nowrap" as const,
};

export const infoText = {
  fontWeight: "bold",
  fontSize: "13px",
};

export const commentArea = {
  width: "100%",
  height: "100px",
  borderRadius: "0.5rem",
  borderColor: "black",
  border: "1px solid #000000",
  padding: "8px 12px",
  fontSize: "13px",
  textAlign: "left" as const,
};

// プロフィール編集モーダル
export const editProfileKoumoku = {
  width: "100%",
  borderRadius: "0.2rem",
  color: "black",
  fontWeight: "bold",
  fontSize: "13px",
  background: "#e5e5e5",
  textAlign: "left" as const,
  padding: "2px 0 2px 1rem",
};
export const editProfileSubmit = {
  width: "40%",
  height: 40,
  borderRadius: "30px",
  color: "white",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  background: "#37a59a",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "#37a59a",
  },
};
export const editProfileCancel = {
  width: "40%",
  height: 40,
  borderRadius: "30px",
  color: "black",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  background: "white",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "white",
  },
};

//taisen
export const taisenRoomOuterDiv = {
  width: "150px",
  height: "120px",
  background: "white",
  borderRadius: " 1rem",
  border: "3px solid #37a59a",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
};

export const taisenRoomInnerDiv1 = {
  display: "flex",
  background: "#37a59a",
  height: "25px",
  borderRadius: " 0.5rem 0.5rem 0 0",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center" as const,
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
};

export const taisenRoomInnerDiv2 = {
  display: "flex",
  height: "95px",
  alignItems: "center",
  justifyContent: "center",
};

export const taisenRoomButton = {
  width: "80%",
  height: 50,
  borderRadius: "30px",
  color: "#27a6ba",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  fontWeight: "bold",
  background: "white",
  border: "3px solid #27a6ba",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "white",
  },
};

//taisenroom
export const taisenResultButtonDisable = {
  width: "50%",
  height: 50,
  borderRadius: "30px",
  color: "white",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  fontWeight: "bold",
  background: "#dbdbdb",
  // border: "3px solid #27a6ba",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "#dbdbdb",
  },
};
export const taisenResultButtonActiv = {
  width: "50%",
  height: 50,
  borderRadius: "30px",
  color: "white",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  fontWeight: "bold",
  background: "#37a59a",
  // border: "3px solid #27a6ba",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "#dbdbdb",
  },
};

// qr戻るボタン
export const qrBackButton = {
  width: "80%",
  height: 45,
  borderRadius: "30px",
  color: "black",
  boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
  fontSize: "16px",
  fontWeight: "bold",
  background: "white",
  border: "2px solid #aaaaaa",
  "&:hover": {
    boxShadow: "1px 2px 0px rgba(191,191,191, 1)",
    background: "white",
  },
};

export const skewedLeftGrayBlockStyle = (
  top: number,
  width: string,
  height: number
): React.CSSProperties => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  paddingLeft: "3rem",
  fontSize: 18,
  fontWeight: "bold",
  top: top,
  left: 0,
  width: width,
  height: `${height}px`,
  backgroundColor: "#e5e5e5",
  zIndex: 100,
  clipPath: `polygon(0 0, 100% 0, calc(100% - ${0.6 * height}px) 100%, 0 100%)`,
});

export const skewedRightBlockStyle = (
  top: number,
  width: string,
  height: number
): React.CSSProperties => ({
  position: "absolute",
  top: top,
  right: 0,
  width: width,
  height: `${height}px`,
  backgroundColor: "#27a5bb",
  zIndex: 100,
  clipPath: `polygon(${0.6 * height}px 0, 100% 0,  100% 100%, 0 100%)`,
});

export const footerBackBtnArea = {
  width: "100%",
  maxWidth: `${maxWidth}px`,
  height: "70px",
  background: "#37a59a",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

//対戦結果報告
export const resultPlayerBorder = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center" as const,
  width: "130px",
  height: "130px",
  borderRadius: "1rem",
  border: "3px solid #37a59a",
};
export const resultPlayerSelectedIcon1 = {
  position: "absolute" as const,
  bottom: "-15px",
  left: "center",
  background: "#db6767",
  width: "80px",
  height: "30px",
  border: "3px solid #ffffff",
  borderRadius: "1rem",
  color: "white",
  display: "flex",
  alignItems: "center" as const,
  justifyContent: "center",
  fontSize: "12px",
  fontWeight: "bold",
};
export const resultPlayerSelectedIcon2 = {
  position: "absolute" as const,
  top: "-20px",
  left: "-13px",
  background: "white",
  width: "30px",
  height: "30px",
  border: "3px solid #db6767",
  borderRadius: "20px",
  display: "flex",
  alignItems: "center" as const,
  justifyContent: "center",
};

// メンバーリスト
export const grayEllipseStyleUserList = {
  width: "50px",
  height: "15px",
  borderRadius: "15px",
  color: "white",
  fontWeight: "bold",
  fontSize: "10px",
  background: "#70C3C2",
  textAlign: "center" as const,
};
