import React, { FC, useEffect, useRef, useState } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  RouteProps,
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import AppBar from "./components/AppBar";
import Home from "./pages/home/home";
import LoginPage from "./pages/home/loginPage";
import Box from "@mui/material/Box";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfServices from "./pages/TermsOfServices";
import Footer from "./components/Footer";
import { Taisen } from "./pages/taisen/taisen";
import { TaisenRoom } from "./pages/taisen/taisenroom";
import EditProfile from "./pages/editProfile/editProfile";
import EditProfileRaket from "./pages/editProfile/editProfileRaket";
import { useAuth } from "./components/firebase/authContext";
import { PrivateRoute, LoginedRoute } from "./PrivateRoute";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import UserList from "./pages/userList/userList";
import UserProfile from "./pages/userList/userProfile";
import RateRanking from "./pages/userList/rateRanking";
import RoomHistory from "./pages/taisen/roomhistory";
import ScoreCounterPage from "./pages/scoreCounter/scoreCounterPage";

export function App() {
  const apptheme = createTheme({
    typography: {
      fontFamily: [
        "Roboto",
        '"Noto Sans JP"',
        '"Helvetica"',
        "Arial",
        "sans-serif",
      ].join(","),
    },
  });
  const { currentUser } = useAuth();
  // const location = useLocation();

  return (
    <ThemeProvider theme={apptheme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfServices />} />
            <Route path="score-counter" element={<ScoreCounterPage />} />
            <Route
              path="login"
              element={<LoginedRoute component={<LoginPage />} redirect="/" />}
            />
            <Route
              path="/"
              element={<PrivateRoute component={<Home />} redirect="/login" />}
            />
            {/* <Route
              path="/myprofiel"
              element={<PrivateRoute component={<Home />} redirect="/login" />}
            /> */}
            <Route
              path="/userlist"
              element={
                <PrivateRoute component={<UserList />} redirect="/login" />
              }
            />
            <Route
              path="/rateranking"
              element={
                <PrivateRoute component={<RateRanking />} redirect="/login" />
              }
            />
            <Route
              path="/taisen"
              element={
                <PrivateRoute component={<Taisen />} redirect="/login" />
              }
            />
            <Route
              path="/taisen/taisenroom/:roomId"
              element={
                <PrivateRoute component={<TaisenRoom />} redirect="/login" />
              }
            />
            <Route
              path="/editprofile"
              element={
                <PrivateRoute component={<EditProfile />} redirect="/login" />
              }
            />
            <Route
              path="/editprofileraket"
              element={
                <PrivateRoute
                  component={<EditProfileRaket />}
                  redirect="/login"
                />
              }
            />
            <Route
              path="/roomhistory"
              element={
                <PrivateRoute component={<RoomHistory />} redirect="/login" />
              }
            />
          </Routes>
          <AppBar />
          <Footer></Footer>
          {/* 条件分岐で AppBar を表示 */}
          {/* {currentUser ? <AppBar /> : <div></div>} */}
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}
